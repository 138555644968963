.report-registry-page {
    display: flex;
    height: 95%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.content-container {
    width: 90%;
    height: 85%;
    padding: 1rem 2rem;
    overflow: scroll;
    background-color: #fff;
    gap: 2%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    box-shadow: 6px 6px 10px #7e7e7e;
}

.question-container {
    width: 100%;
    margin-top: 3%;

    .registry-report-body-question-dissertative,
    .registry-report-body-question-checkbox,
    .registry-report-body-question-checkbox {
        margin-bottom: 20px;

        input[type="text"],
        input[type="date"] {
            width: 30vw;
            height: 3vh;
            border: none;
            border-bottom: 1px solid #c3c3c3;
            outline: none;

            &:focus {
                border-bottom: 1px solid #9a1915;

                transition-duration: 0.3s;
            }
        }

        p {
            margin-bottom: 2%;
        }
    }
}

.registry-report-header-container {
    width: 100%;
    height: 3%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10%;
    flex-direction: column;

    .registry-report-title {
        font-size: clamp(8px, 1.5vw, 18px);
        color: $font-color-dark;
        font-weight: $font-semiBold;
    }

    .registry-report-title-line {
        width: 100%;
        height: 0.05vw;
        background-color: $font-color-dark;
    }
}

.registry-report-body-container {
    width: 100%;
    height: 95%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    overflow-y: scroll;

    // .registry-report-body-question-container{
    //     width: 48%;
    //     height: 100%;

    //     background-color: #fff;
    // }

    .registry-report-body-question-container {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        overflow-y: scroll;
        gap: 2%;

        // background-color: #fff;

        .registry-report-body-question-dissertative {
            width: 100%;
            height: auto;
            // min-height: 120px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding: 0 1rem;
            gap: 10%;

            p {
                width: 100%;
                height: auto;
                word-break: break-all;

                font-size: clamp(4px, 1.7vw, 16px);
                color: $font-color-dark;
                font-weight: $font-semiBold;
            }

            input {
                width: 100%;

                font-size: clamp(2px, 1.5vw, 12px);
                color: $font-color-dark;
                font-weight: $font-semiBold;

                border: 0;
                border-bottom: 1px solid $font-color-dark;
                border-radius: 3px;

                padding-left: 1%;
                padding-bottom: 1%;

                outline: none;

                margin-top: 10px;
            }
        }

        .registry-report-body-question-checkbox {
            width: 100%;
            height: auto;

            padding: 0 1rem;

            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;

            gap: 1vh;

            p {
                width: 100%;
                height: 40%;

                font-size: clamp(4px, 1.5vw, 14px);
                color: $font-color-dark;
                font-weight: $font-semiBold;
            }

            .registry-report-body-checkbox-container {
                width: 100%;
                height: auto;

                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 2%;

                input[type="checkbox"] {
                    position: relative;
                    cursor: pointer;

                    width: 15px;
                    height: 15px;
                    border: 1px solid $font-color-dark;
                    border-radius: 0;
                }

                input[type="checkbox"]:before {
                    content: "";
                    display: block;
                    position: absolute;

                    top: 0;
                    left: 0;
                    background-color: #9a1915;
                }

                input[type="checkbox"]:checked:after {
                    content: "";
                    display: block;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    position: absolute;
                    top: 15px;
                    left: 9px;
                }

                input[type="radio"] {
                    position: relative;
                    cursor: pointer;

                    width: 15px;
                    height: 15px;
                    border: 1px solid $font-color-dark;
                    border-radius: 0;
                }

                input[type="radio"]:before {
                    content: "";
                    display: block;
                    position: absolute;

                    top: 0;
                    left: 0;
                    background-color: #9a1915;
                }

                label {
                    font-size: clamp(2px, 1.5vw, 14px);
                    color: $font-color-dark;
                    font-weight: $font-semiBold;
                }
            }
        }
    }
}

.registry-report-button {
    width: 5vw;
    height: 3vh;
    background-color: $bg-senai;
    border-radius: 5px;
    border: none;

    font-size: clamp(8px, 1.5vw, 12px);
    color: $bg-base;
    font-weight: $font-regular;

    cursor: pointer;
    transition: 0.4s;

    &:hover {
        width: 5.5vw;
        height: 3.5vh;

        box-shadow: 5px 5px 10px $font-modal-color;
        font-size: clamp(10px, 1.5vw, 14px);
    }
}
