.not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
}

.not-found-box {
    h3 {
        font-size: 2.2rem;
    }

    p {
        margin-top: 10px;
        font-size: 1.2rem;
        font-weight: $font-thin;
    }
}

.not-found-return {
    p {
        text-align: center;
        margin-top: 32px;
        color: $bg-senai;
        text-decoration: underline;
        font-weight: $font-regular;
    }
}