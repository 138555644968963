.modal-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 86.3vw;
  height: 92vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
}
