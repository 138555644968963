.container {
    width: 100vw;
    height: 100vh;
    background-color: #9A1915;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    min-width: 150px;

    max-width: 200px;
}

.main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.form {
    background-color: #fff;
    min-width: 310px;
    min-height: 420px;
    width: 40%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
}

.formTitle {
    font-size: 22px;
    font-weight: 600;
    color: #5B5A5A;
    padding-top: 5%;

}

.formSubTitle {
    font-size: 13px;
    font-weight: 400;
    color: #5B5A5A;
}

.passwordContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 10%;

    span {
        font-size: 14px;
        margin-bottom: 8px;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1.5px solid;
        width: 90%;

        .field {
            width: 90%;
            outline: none;
            border: none;
            padding: 2px;
        }

        .passwordVisible {
            border: none;
            background-color: #fff;
        }

        .icon {
            width: 20px;
            height: 20px
        }
    }

    .strongPassword {
        font-size: 11px;
        font-weight: 400;
        margin-top: 3px;
        color: #189A15;
    }

    .mediumPassword {
        font-size: 11px;
        font-weight: 400;
        margin-top: 3px;
        color: #0000ff;
    }

    .weakPassword {
        font-size: 11px;
        font-weight: 400;
        margin-top: 3px;
        color: #9A1915;
    }

}

.defineButtonFalse {
    width: 60%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    background-color: #868686;
    border-style: none;

    span {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
}

.defineButtonTrue {
    width: 60%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    background-color: #189A15;
    border-style: none;
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #4ec94c;

    }

    span {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
}