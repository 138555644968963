$floating-button-diameter : clamp(64px, 4vw, 80px);
$floating-button-offset : 25px;

.floating-menu-container {
    transition: 1s;
}

.floating-menu-toggle-button {
    position: absolute;
    z-index: 10;
    
    right: $floating-button-offset;
    bottom: $floating-button-offset;
    width: $floating-button-diameter;
    height: $floating-button-diameter;
    padding: 8px;

    background-color: $bg-senai;

    // border-style: solid;
    // border-width: 4px;
    // border-color: #fff;
    border-radius: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    transition: background .15s ease-out;

    &:hover {
        background-color: #fff;

        span {
            background-color: $bg-senai;
        }
    }

    span {
        display: block;
        width: 75%;
        height: 5px;

        background-color: #fff;

        border-radius: 3px;

        transition: opacity 0.25s ease-out, transform 0.25s ease-out, background .15s ease-out;

        &:nth-child(2) {
            margin: clamp(7.5px, .5vw, 10px) 0px;
        }
    }

    &.active{

       span{

            &:first-child{
                transform: translateY(clamp(12.5px,.7vw,15px)) rotate(135deg);

            }

            &:nth-child(2){
                transform: scale(0);
                opacity: 0;
            }

            &:last-child{
                transform: translateY(clamp(-15px,-.7vw,-12.5px)) rotate(-135deg);
            }
       }
    }
}

.menu-item-container {
    width: clamp(125px, 25vw, 200px);
    height: fit-content;
    padding: clamp(2.5px, .5vw, 5px) 0;

    background-color: $bg-senai;

    position: absolute;

    right: 25px;
    bottom: calc($floating-button-offset + $floating-button-diameter + 1vh);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5vw;

    border-radius: 5px;


    z-index: -99;
    opacity: 0;
    transition: opacity .15s ease-out;

    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    &.active {
        z-index: 2;
        opacity: 1;
    }

    .menu-item {
        width: 100%;
        padding: .75vw;
        color: #fff;

        transition: background .15s ease-out;

        cursor: pointer;

        &:hover {
            background-color: #fff;
            color: $bg-senai;
        }
    }
}