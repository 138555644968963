.notification-toggle-button {   
    width: clamp(28px, 2vw, 42px);
    height: clamp(28px, 2vw, 42px);
    margin-right: max(2vw, 25px);

    border-radius: 100%;
    border-style: none;

    background-color: $bg-senai;

    position: relative;

    cursor: pointer;

    .notification-toggle-button-icon {
        max-width: 100%;
        max-height: 100%;
    }

    .notification-counter {
        $notification-counter-font-size : clamp(.3rem, 1vw, .6rem);

        width: 60%;
        height: 60%;
        position: absolute;
        right: -10%;
        top: 3%;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 100%;
        border-style: solid;
        border-width: clamp(1px, .20vw, 2px);
        border-color: white;

        color: white;

        font-size: $notification-counter-font-size;

        background-color: $bg-senai;
    }
}

.notification-item-container {
    z-index: 24;

    width: clamp(200px, 25vw, 450px);
    max-height: 40vh;
    height: fit-content;

    overflow-y: auto;

    background-color: white;

    position: absolute;
    right: max(2vw, 25px);
    top: 7.5vh;

    display: flex;
    flex-direction: column;
    gap: .5vh;

    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    border-radius: 5px;

    padding: .5vh 0;


    &::-webkit-scrollbar {
        width: 10px;
        height: 50px;
    
    }

    &::-webkit-scrollbar-thumb {
    background: #9a1915;
    }

    &::-webkit-scrollbar-track {
    background: #E7E7E7;
    }

    .notification-item {
        width: 100%;
        max-height: 80px;
        padding: 1vh 1vw;

        display: flex;
        align-items: center;
        gap: clamp(5px, .5vw, 10px);
        justify-content: space-between;

        // cursor: pointer;

        // transition: background .15s ease-out;
        // &:hover {
        //     background-color: white;
        //     .notification-item-message { color: #2f2f2f; }
        // }

        .notification-type-icon {
            height: clamp(24px, 2vw, 32px);
        }

        .notification-delete-icon {
            height: clamp(16px, 2vw, 24px);
            cursor: pointer;
        }

        .message-container {
            display:flex;
            flex-direction: column;
            flex-grow: 1;

            .notification-item-message {
                color: #2f2f2f;
                font-size: clamp(.75rem, 1vw, 1rem);
                margin-bottom: 5px;
            }

            .notification-item-time {
                font-size: clamp(.5rem, .75vw, .75rem);
                color: $font-modal-color;
            }
        }
    }
}