.avaliation-page {
  width: 100%;
  height: 100%;

  display: flex;
}

.avaliation-page-main-container {
  width: 70%;
  height: 100%;
}

.avaliation-page-proposal-name {
  width: 100%;

  p {
    text-align: center;

    font-size: clamp(1rem, 1.2vw, 1.3rem);
    font-weight: 500;
  }
}

.avaliation-page-container {
  width: 100%;
  height: 70%;

  padding-left: 3%;
  padding-right: 3%;

  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
}

.avaliation-page-header {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  height: 7%;
  width: 100%;

  p {
    text-align: left;

    font-size: clamp(1.1rem, 1.2vw, 1.5rem);
    font-weight: 500;
  }
}

.avaliation-page-body {
  width: 100%;
  height: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.avaliation-page-text-container {
  width: 50%;
  height: 100%;

  padding-right: 5%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    text-align: left;
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
    font-weight: 400;
  }
}

.avaliation-page-stars-container {
  width: 30%;

  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  display: flex;
  justify-content: space-around;

}

.avaliation-page-star {
  color: #f1da05;
  font-size: clamp(24px, 1.2vw, 36px) !important;
  cursor: pointer;
}

.all-avaliation-page-container {
  height: 7%;
  width: 100%;

  padding-right: 20%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;

  justify-content: space-between;

  .avaliation-page-stars-container {
    width: 37%;
  }

  span {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: left;
  }
  .all-avaliation-page-text-container {
    width: 100%;
    height: 40%;
    margin-bottom: 5%;
    p {
      width: 100%;
      height: 100%;
      display: flex;

      align-items: center;
      font-size: clamp(1.1rem, 1.2vw, 1.5rem);
      font-weight: 500;
      text-align: left;
    }
  }
}

.avaliation-page-description-container {
  width: 30%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.avaliation-page-description {
  width: 85%;
  height: 85%;
  border: 3px solid #444444;
  margin-top: -18%;

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  p {
    width: 95%;
    font-size: clamp(1rem, 1.2vw, 1.4rem);
    color: #444;
    font-weight: 600;
    text-align: left;
  }

  textarea {

    overflow-y: scroll;
    width: 90%;
    height: 90%;

    font-size: clamp(.4rem, 1vw, 1.2rem);
    resize: none;
    color: #444;
    font-weight: 400;
    text-align: left;
    line-height: 2;

    background-color: transparent;
    border: none;
    outline: none;
  }
}

.avaliation-page-reopen {
  width: 100%;
  height: 25%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-right: 5%;
}

.avaliation-page-reopen-button {
  width: 40%;
  height: 30%;
  background-color: #595959;
  border: none;
  border-radius: 10px;

  transition: 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #444444;

    p {
      color: rgb(234, 234, 234);
    }
  }

  p {
    font-size: clamp(1.5rem, 1.2vw, 2rem);
    color: $bg-base;
    font-weight: 600;
    text-align: center;
  }
}
