@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
@import "./vars.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $font-base;
}

@media (max-width: 1080px) {
  html {
    font-size: 98.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 83.5%;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f4f6f6;
}

#root {
  z-index: 0;
  @import './src/components/Login/index.scss';
  @import './src/components/Dashboard/index.scss';
  @import './src/components/Journey/Managment/index.scss';
  @import './src/components/Filter/index.scss';
  @import './src/components/Filter/specialty.scss';
  @import './src/components/Enterprises/index.scss';
  @import './src/components/Enterprises/card.scss';
  @import './src/components/Enterprises/ProfileDetails.scss';
  @import './src/components/Evaluations/index.scss';
  @import './src/components/Evaluations/QuestionForm.scss';
  @import './src/components/Evaluations/EvaluationTable/index.scss';
  @import './src/layout/sidebar.scss';
  @import './src/layout/navbar.scss';
  @import './src/components/TechProduct/';
  @import './src/components/Journey/Managment/FilterJourneyComponent/FilterJourneyComponent.scss';
  @import './src/components/Journey/Managment/DigitalTransformation/Card.scss';
  @import './src/components/Journey/Managment/DigitalTransformation/Elevator.scss';
  @import './src/components/Journey/Managment/DigitalTransformation/Floors.scss';
  @import './src/components/Journey/Managment/DigitalTransformation/Header.scss';
  //linkando arquivos de estilização  dos lados do dashboard
  @import './src/components/Journey/Managment/DigitalTransformation/side/side-center.scss';
  @import './src/components/Journey/Managment/DigitalTransformation/side/side-right.scss';
  @import './src/components/Journey/Managment/DigitalTransformation/side/side-left.scss';
  @import './src/components/Journey/Enterprise';
  @import './src/components/Journey/Enterprise/Card/index.scss';
  /* importando arquivos de estilo das modais */
  @import './src/components/Modals';
  @import './src/components/Modals/TechProductModal.scss';
  @import './src/components/Modals/CreateSpecialityModal.scss';
  @import './src/components/Modals/CreateEnterpriseModal.scss';
  @import './src/components/Evaluations/modals/CreateQuestion/CreateTable.scss';
  @import './src/components/Modals/ConfirmationModal.scss';
  @import './src/components/Modals/CreateUserModal.scss';
  @import './src/components/Modals/AddContactModal.scss';
  @import './src/components/Modals/AvaliationModal.scss';
  @import './src/components/Modals/DiagnosticModal.scss';
  @import './src/components/Modals/ProcessTypeModal.scss';
  @import './src/components/Modals/ProposalsModals.scss';
  @import './src/components/Modals/EditSpecialty.scss';
  @import './src/components/Modals/VinculationProposalModal.scss';
  @import './src/components/utils/ModalContainer/index.scss';
  @import './src/components/EnterpriseToken/index.scss';
  @import './src/components/Modals/Form/index.scss';
  @import './src/layout/CollapsibleMenu.scss';
  @import './src/components/Proposals/index.scss';
  @import './src/components/Proposals/SatisfactionReport/index.scss';
  @import './src/components/Management/index.scss';
  @import './src/components/FloatingMenu/index.scss';
  @import './src/components/Notification/index.scss';
  @import './src/layout/NotFound.scss';
  @import './src/layout/PasswordRecovery.scss';
  @import './src/components/Enterprises/Semaphore/Semaphore.scss';
  @import './src/components/Enterprises/ReportsRegistry/index.scss';
  @import './src/components/Enterprises/ReportsRegistry/Evaluation/index.scss';
  @import './src/components/EnergyEfficiency/ObservationViewModal/styles.scss';
  @import './src/components/CompletionRecord/index.scss';
  @import './src/components/CompletionRecord/ReportRegistrySubMenu/index.scss';
  @import './src/components/CompletionRecord/FormEvaluation/index.scss';
  @import './src/components/utils/ConfirmationModal';
  @import './src/components/utils/AlertPopup';
  @import './src/components/Evaluations/modals//CreateQuestion/CreateQuestionModal.scss';
  @import './src/components/Evaluations/modals//CreateQuestion/CreateAnswer.scss';
  @import './src/components/Evaluations/modals/CreateEvaluationModal.scss';
  @import './src/components/DataVisualization/DataTable.scss';
  @import './src/components/Specialties/index.scss';
  @import './src/components/Users/index.scss';
}

button[disabled] {
  opacity: 0.5 !important;
}