@mixin row-container($justify-content, $padding, $gap) {
  display: flex;
  justify-content: $justify-content;
  padding: $padding;
  gap: $gap;
}

canvas {
  width: 70%;
}

#consultant-data-content{
  display: flex;
  flex-direction: column;
  padding: 0rem 1.5rem ;
  font-size: 0.8rem;
  width: 100%; 
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 30px;
}


.profile-details-container {
  overflow: auto;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 16px 16px ;

  .profile-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 98%;

    .row-container-top {
      @include row-container(none, 0, 0);

      .company-information {
        display: flex;
        flex: 2;
        flex-direction: row;
        flex-wrap: wrap;

        .main-informations {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h2 {
            font-size: 1.25rem;
          }

          .divider {
            width: 100%;
            height: 2px;
            background-color: rgb(0, 0, 0);
            border-radius: 5px;
            margin-bottom: 1rem;
          }

          p {
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 4rem;
            }
          }
        }

        .container-contacts {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gap: 5rem;

          .container-title-contact {
            display: flex;
            width: 100%;
            font-size: 1rem;
            gap: 1rem;
            margin-bottom: 1rem;

            button {
              background-color: transparent;
              border: none;
              outline: none;
              cursor: pointer;
            }
          }

          .container-contact-details {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            gap: 1rem;
          }
        }
      }
    }

    .container-contacts {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 5rem;

      .container-title-contact {
        display: flex;
        flex: 1;
      }
    }

    .container-bottom {
      display: flex;
      justify-content: space-between;

      #process-container {
        @include row-container(space-between, 0, 0);
        flex-direction: column;
        width: 79%;
        height: 100%;
        overflow: hidden;

        .container-charts {
          display: flex;
          flex-direction: column;
          padding: 1vh;
          width: 100%;
          height: 40vh;
          background-color: #ffffff;
          border-radius: 8px;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;

          .container-chart-title {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .container-chart-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            height: 90%;
            text-align: center;

            .radar-chart-container,
            .bar-chart-container {
              width: 60%;
              height: 100%;
            }

            .radar-chart-container {
              .evaluation-result-container {
                width: 100%;
                height: 8%;
                .chart-evaluation {
                  span {
                    font-weight: bold;
                    color: #9a1915;
                    margin-right: 2vh;
                    font-size:  0.7rem !important;
                  }
                }
              }
            
              .chart-container {
                display: flex;
                align-items: center;
                justify-content:center ;
                width: 75%;
                height: 85%;
                margin-left: 0%;
             
                  .chart {
                    background-color: #0f53a9;
                  }

                  #chart-container-content {
                    p{
                      font-size: 12px;
                      width: max-content;
                    }
                  }
                }
            }

            .bar-chart-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;

              .digital-technologies-title {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 10%;

                h3 {
                  color: #9a1915;
                  font-size: 1rem;
                }
              }

              .bar-chart-wrapper {
                width: 100%;
                height: 90%;
                padding: 1vh;

                .container-bars {
                  display: flex;
                  align-items: flex-end;
                  height: 100%;
                  width: 100%;

                  .container-bar-and-text {
                    height: 100%;
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;

                    .bar {
                      width: 40%;
                      transition: all 0.5s ease-in-out;
                      background-color: $border-base;
                      border-radius: 5px;
                      // width: 10%;
                      &.selected-column-bar {
                        background-color: #0f53a9;
                      }
                    }

                    .container-bar-title {
                      height: auto;
                      min-height: 2vh;
                      margin-bottom: 1vh;
                      width: 100%;

                      span {
                        font-size: clamp(4px, 1.2vw, 12px);
                        text-align: center;
                        vertical-align: middle;
                        margin-bottom: 0.5rem;
                        align-self: flex-start;

                        &.selected-column-text {
                          color: #0f53a9;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      #side-data-container {
        @include row-container(center, 0, 0);
        height: 82vh;
        width: 20%;
        background-color: #ffffff;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-radius: 8px;
        overflow: hidden;

        #energetic-efficiency-container {
          width: 100%;
          height: 40%;
          padding: 5px;
          display: flex;
          flex-direction: column;
          padding-top: 1vh;
          align-items: center;
          justify-content: flex-start;

          .company-info-title {
            width: 100%;
            height: 10%;
            margin-top: 2%;

            h2 {
              text-align: center;
              font-weight: normal;
              font-size: clamp(4px, 0.6vw, 24px);

              // font-size: 1rem;
            }
          }

          .semaphore-container {
            width: 100%;
            height: 90%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              margin-left: 1vw;
              width: clamp(70%, 10vw, 200%);
            }
          }
        }

        #item-divisor {
          width: 100%;
          height: 1%;
          display: flex;
          justify-content: center;

          #divisor-line {
            width: 75%;
            height: 1px;
            background-color: black;
          }
        }

        #company-info-container {
          width: 100%;
          height: 59%;
          display: flex;
          flex-direction: column;

          .company-info-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 50%;
            padding: 0 12.5% 0 12.5%;
            align-items: flex-end;
            justify-content: center;

            #company-details-container,
            #company-data-container {
              display: flex;
              width: 100%;
              flex-direction: column;
              flex-wrap: wrap;
              gap: 0.8vh;

              p {
                font-size: 0.9em;
              }

              #contact-edition-container {
                width: 100%;
                display: flex;
                justify-content: space-between;

                #edition-button-container {
                  cursor: pointer;
                  margin-top: -0.5vh;

                  img {
                    width: 20px;
                    height: 20px;
                  }

                  span {
                    color: #3e80b0;
                    text-decoration: underline;
                  }
                }
              }
            }

            #company-data-container {
              height: 60%;
            }

            #contact-pagination-container {
              width: 100%;
              display: flex;
              justify-content: center;

              // margin-top: 5vh;
              #pagination-button-container {
                width: 100%;
                display: flex;
                justify-content: center;

                span {
                  margin: 0 1vw 0 1vw;
                }

                img {
                  margin-top: 0.5vh;
                  width: 9px;
                  height: 9px;
                  cursor: pointer;
                }
              }
            }

            .company-info-title {
              width: 100%;
              height: 10%;

              h2 {
                text-align: left;
                text-align: center;
                font-weight: normal;
                font-size: 1rem;
              }

              h3 {
                font-size: 1rem;
              }

              margin-bottom: 2vh;
            }
          }
        }
      }

      .container-charts-bars {
        display: flex;
        position: relative;
        width: 100%;
        flex-wrap: wrap;
        gap: 5rem;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}

@media (max-width: 1554px) {
  .row-container-top {
    flex-direction: column;
    padding-left: 0 !important;
  }

  .company-information {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .maturity-level {
      justify-content: flex-start !important;
    }
  }
}

@media (max-width: 1480px) {
  .main-informations {
    h2 {
      font-size: 0.8rem !important;
    }
  }

  .container-chart-title {
    h4 {
      font-size: clamp(0.5rem, 0.8vw, 1rem);
    }
  }

  .radar-chart-container {
    .evaluation-result-container {
      width: 100%;
      height: 8%;

      span {
        font-weight: bold;
        font-size: 0.8rem !important;
        margin-right: 2vh;
      }
    }
  }

  .digital-technologies-title {
    margin-top: 1vh;

    h3 {
      font-size: clamp(0.5rem, 0.8vw, 1rem) !important;
    }
  }

  .container-bar-title {
    line-height: 1vh;

    span {
      font-size: 0.5rem !important;
      text-align: center;
      margin-bottom: 0.5rem;
      align-self: flex-start;

      &.selected-column-text {
        color: #0f53a9;
      }
    }
  }

  .company-info-item {
    .company-info-title {
      h3 {
        font-size: 0.8rem !important;
      }
    }

    #company-details-container {
      font-size: 0.6rem;

      p {
      }
    }

    #company-data-container {
      font-size: 0.6rem;

      #contact-edition-container {
        img {
          width: 10px !important;
          height: 10px !important;
        }
      }
    }

    #contact-pagination-container {
      font-size: 0.6rem;

      #pagination-button-container {
        img {
          width: 6px !important;
          height: 6px !important;
        }
      }
    }
  }

  #energetic-efficiency-container {
    .company-info-title {
      h2 {
        font-size: 0.8rem !important;
      }
    }
  }

  .profile-details-container {
    padding: 12px 12px;
  }
}

@media (max-width: 1280px) {
  #consultant-data-content{
    margin-right: 0;
  }
  .chat-222{ 
      height: 160px !important;  
      width: 160px !important;  
  }
  .chart-container {

      #chart-container-content {
        p{
          font-size: 7px !important;
        }
      }
    }
}