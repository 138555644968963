.create-evaluation-modal-container {
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 99;

  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;
}

.create-evaluation-modal {
  width: 30%;
  height: auto;
  min-height: 20%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1vh;


  border-radius: 5px;
  background-color: $bg-base;
  padding: 1%;
}

.create-evaluation-header {
  width: 100%;
  height: 25%;

  display: flex;
  align-items: center;

  justify-content: space-between;

  h2 {
    font-size: clamp(.8rem, 4vw, 1rem);
    color: $bg-senai;
    // font-weight: $font-medium;
  }

  span {
    cursor: pointer;
  }
}

.create-evaluation-body {
  width: 100%;
  min-height: 50%;
  height: auto;

  gap: 1vh;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .create-evaluation-input {
    width: 100%;
    height: 50%;

    display: flex;
    align-items: center;
    justify-content: space-between;


    p {
      font-size: clamp(6px, 1.5vw, 12px);
      color: $font-color-dark;
      font-weight: $font-medium;
    }

    input,
    select {
      width: 70%;
      height: 3vh;
      background-color: $bg-base;
      border: 0;
      border-bottom: 2px solid $font-color-dark;
      border-radius: 3px;
      font-size: 14px;
      padding-left: 2%;

      outline: none;

      font-size: clamp(4px, 1.5vw, 12px);
      color: $font-color-dark;
      font-weight: $font-medium;

      &:focus {
        outline: none;
        border-color: $bg-senai;
      }
    }
  }

}

.create-evaluation-tech-products-selected-container {
  width: 100%;
  min-height: 3vh;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: .5vw;
  margin-bottom: 12px;

  .create-evaluation-tech-products-selected-card {
    min-width: 3vw;
    width: auto;
    height: 50%;

    background-color: $bg-base;
    border-radius: 10px;
    border: 1px solid $font-color-dark;

    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: .1vw;

    padding: 0 .2vw;

    p {
      font-size: clamp(2px, 1.5vw, 10px);
      color: $font-color-dark;
      font-weight: $font-medium;
    }

    div {
      cursor: pointer;
      font-size: clamp(6px, 1.5vw, 14px);
      color: $bg-senai;
      font-weight: $font-medium;

    }
  }
}

.create-evaluation-footer {
  width: 100%;
  height: 25%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.button-base-style {
  width: 50%;
  display: flex;
  height: 2vw;
  font-size: clamp(0.4rem, .8vw, 1.25rem);
  align-items: center;
  justify-content: center;

  border-radius: 3px;
  border: 1px solid $border-base;

  font-weight: $font-regular;
  color: $bg-base;

  cursor: pointer;

  background-color: $bg-senai;
  transition: background .3s ease-out;


  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}