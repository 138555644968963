.modal-create-enterprise-wrapper {
    background-color: $bg-screen-modal;
    width: $modals-width;
    height: 92%;
    z-index: 99;
    position: fixed;
    top: 8%;
    right: 0;
    transform: 1s;
    display: flex;
    align-items: center;
    justify-content: center;
}


.modal-create-enterprise-container {
    width: 450px;
    height: auto;
    border: 1px solid $border-base;
    background-color: $bg-modal;
    padding: 20px;

    display: flex;
    flex-direction: column;
}

.modal-create-enterprise-close-container{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
}

.close-button{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modal-create-enterprise-title {
    margin: 0 0 0 30px;
    font-size: 18px;
    color: $bg-senai;
    font-weight: $font-regular;
    display: flex;
    justify-content: space-around;
}

.modal-create-enterprise-form {
    margin: 20px 0 0 30px;
    display: flex;
    align-items: center;
    

    form {
        max-width: 400px;
      }

   
}

.modal-create-enterprise-form-button {
        background-color: $bg-senai;
        width: 329px;
        height: 49px;
        border-radius: 3px;
        margin-left: 8px;
        margin-top: 8px;
        border: 1px solid $border-base;
        color: $bg-base;
        font-weight: $font-thin;
}

.modal-create-enterprise-form-additional-contact {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    color: $bg-base;
    font-weight: $font-thin;

    button {
        cursor: pointer;
        background-color: #0000;
        border-color: #0000;

        span{
                font-size: 20px;
                font-weight: $font-regular;
        }
    }
}

.modal-create-enterprise-form-input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        margin-right: 20px;
        font-weight: $font-thin;
        font-size: 14px;
    }

    input {
        max-width: 240px;
        width: 240px;
        height: 32px;
        background-color: $bg-base;
        border: 1px solid $border-base;
        border-radius: 3px;
    }

    margin-bottom: 12px;
}

.switch {
    position: relative;
    display: inline-block;
    width: clamp(20px, 2vw, 60px );
    height: clamp(12px, 1.2vw, 30px );
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
    position: relative;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: clamp(8px, .7vw, 23px);
    width: clamp(8px, .7vw, 23px);
    left: 4px;
    bottom: clamp(3.5px, .1vw, 5px) ;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: $font-color-dark;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $font-color-dark;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(clamp(6px, .9vw, 32px));
    -ms-transform: translateX(clamp(6px, .9vw, 32px));
    transform: translateX(clamp(6px, .9vw, 32px));
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }