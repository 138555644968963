.alert-popup {
    width: clamp(300px, 40vw, 500px);

    position: absolute;
    right: 32px;
    bottom: 32px;
    z-index: 9999;

    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: white;

    background-color: white;

    overflow: hidden;
    display: none;

    &.visible {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
    }

    .progress-container {
        width: 100%;
        height: clamp(0.5rem, 4vw, 0.75rem);

        position: absolute;
        top: 0px;

        background-color: $font-form;

        .progress-bar {
            height: 100%;

            background-color: $bg-senai;
            transition: width 0.5s ease-in-out;

            &.confirm {
                background-color: #79b555;
            }
        }
    }

    .alert-icon {
        height: clamp(24px, 4vw, 32px);
        width: clamp(24px, 4vw, 32px);

        margin-left: 24px;
    }

    .message {
        margin: 32px 10px;
        margin-right: 24px;
        font-size: clamp(0.75rem, 4vw, 1.25rem);
        color: $bg-senai;

        &.confirm {
            color: #79b555;
        }
    }
}
