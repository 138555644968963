.create-question-modal-container {
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 99;

  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;
}

.create-question-modal {
  width: 75%;
  height: 75%;

  border-radius: 5px;
  background-color: $bg-base;
  padding: 16px;
}

.create-question-header {
  width: 100%;
  height: 5%;

  display: flex;
  align-items: center;

  justify-content: space-between;

  margin-bottom: 1%;

  p {
    font-size: clamp(4px, 4vw, 16px);
    color: $bg-senai;
    font-weight: $font-medium;
  }

  span {
    cursor: pointer;
  }
}

.create-question-body {
  width: 100%;
  height: 90%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4%;

  .create-question-input {
    width: 100%;
    height: 5%;

    gap: 2%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      font-size: clamp(4px, 4vw, 10px);
      color: $font-color-dark;
      font-weight: $font-medium;
    }

    textarea,
    input {
      width: 100%;
      height: 80%;
      background-color: $bg-base;
      border: 0;
      border-bottom: 2px solid $font-color-dark;
      border-radius: 3px;
      font-size: 14px;
      padding-left: 1%;

      resize: none;

      font-size: clamp(4px, 4vw, 10px);
      color: $font-color-dark;
      font-weight: $font-medium;
    }
  }

  .create-question-body-row {
    width: 100%;
    height: 5%;

    gap: 15%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .create-question-input-row {
      width: auto;

      min-width: 10%;
      max-width: 30%;

      height: 100%;

      &.switch {
        width: 10%;
      }

      gap: 2%;

      display: flex;
      align-items: center;
      justify-content: space-around;

      p {
        font-size: clamp(4px, 4vw, 10px);
        color: $font-color-dark;
        font-weight: $font-medium;
      }

      input,
      select {
        width: 100%;
        // max-width: 15vw;
        height: 80%;
        background-color: $bg-base;
        border: 0;
        // background-color: aqua;
        border-bottom: 2px solid $font-color-dark;
        border-radius: 3px;
        font-size: 14px;
        padding-left: 2%;

        font-size: clamp(4px, 4vw, 10px);
        color: $font-color-dark;
        font-weight: $font-medium;
      }
    }
  }
}

.create-question-footer {
  width: 100%;
  height: 5%;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 20%;
    height: 80%;
    background-color: $bg-senai;
    border-radius: 5px;
    border: none;

    font-size: clamp(2px, 1.5vw, 12px);
    color: $bg-base;
    font-weight: $font-regular;

    cursor: pointer;
    transition: 0.4s;

    &:hover {
      width: 25%;
      height: 85%;

      box-shadow: 5px 5px 10px $font-modal-color;
      font-size: clamp(4px, 1.5vw, 14px);
    }
  }
}