$bg-senai: #9a1915;
$bg-senai-dark: #711310;
$bg-base: #f4f6f6;
$bg-base-dark: #dadbdb;
$bg-modal: #f1f1f1;
$bg-screen-modal: rgba(0, 0, 0, 0.6);
$border-base: #e8e8e8;
$input-border: #c4c4c4;
$font-modal-color: #717171;
$font-color-dark: #1f1f1f;
$font-color: #fff;
$font-form: #bcbcbc;
$font-base: "Montserrat", sans-serif;
$font-thin: 100;
$font-light: 200;
$font-regular: 300;
$font-medium: 400;
$font-semiBold: 500;
$font-bold: 600;

$sidebar-width: 12%;
$modals-width: calc(70.2vw + $sidebar-width);
