.tech-product-modal-container,
.confirm-tech-product-delete-modal {
    width: 40%;
    height: auto;
    background-color: white;
    border-radius: 8px;

    padding: 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            color: $bg-senai;
            font-size: clamp(1rem, .8vw, 1.5rem);
        }

        img {
            width: clamp(.75rem, 2vw, 1.25rem);
            cursor: pointer;
        }
    }

    .input-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;

        span {
            width: 40%;
            font-size: clamp(4px, 4vw, 16px);

        }

        input,
        select {
            width: 100%;
            height: 40px;
            border: none;
            border-radius:8px;

            font-size: clamp(4px, 4vw, 16px);

            color: $font-color-dark;
            font-weight: $font-medium;
    
            padding: 0 1rem;
            border-bottom: 2px solid $font-color-dark;
    
            transition: border .25s ease-out;
    
            // font-size: 1.2rem;
    
            &:focus {
                outline: none;
                border-color: $bg-senai;
            }
        }
    }

    button {
        width : auto;
        height : auto;

        padding: 1.5% 4%;
        cursor: pointer;

        background-color: $bg-senai;
        border: none;

        font-size: clamp(4px, 4vw, 16px);

        color: white;
        border-radius: 8px;

        transition : filter 0.25s ease-out;

        &:hover {
            filter: brightness(.9);
        }

        &#cancel {
            background-color: white;
            color: $bg-senai;
            border: 1px solid $bg-senai;
        }

        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    }

    .button-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        button {
            width: clamp(80px, 20vw, 200px);
            height: 32px;
        }
    }
}