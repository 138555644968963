.modal-wrapper {
  width: $modals-width;
  height: 100%;
  position: fixed;
  z-index: 10;

  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;


}

.modal-input {
  background-color: $bg-base;
  border: 0;
  border-bottom: 2px solid $font-color-dark;
  border-radius: 3px;
  font-size: 14px;
  padding-left: 2%;

  outline: none;

  font-size: clamp(4px, 1.5vw, 12px);
  color: $font-color-dark;
  font-weight: $font-medium;

  &:focus {
    outline: none;
    border-color: $bg-senai;
  }

}