.main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pagination-container {
        margin-right: 10vw;
    }
}

.company-container {
    width: 100%;
    // height: 95%;
    max-height: 100vh;
    padding: 3vh 1.2vw;

    display: flex;
    justify-content: center;
    align-items: center;

    .filter-container {
        margin: 0 0.7vw !important;
        height: 100%;
    }
}

.company-card-container {
    background-color: #fff;
    width: 100%;
    height: 100%;
    // padding: 0% 1%;
    padding: .5vh 1.6vw;
    border-radius: 8px;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    overflow-y: hidden;

    &::-webkit-scrollbar {
        width: 10px;
        height: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background: #9a1915;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #e7e7e7;
        border-radius: 8px;
    }
}

.company-cards {
    width: 90%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.next-page-container,
.previous-page-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.none {
    display: none;
}

.company-card-previous-page,
.company-card-next-page {
    width: 3vw;
    height: 2vw;
    background-image: url("../../../../../public/assets/icons/right-arrow-red.png");
    background-size: cover;
    cursor: pointer;
}

.company-card-next-page-disable,
.company-card-previous-page-disable {
    width: 3vw;
    height: 2vw;
}

.company-card-previous-page {
    transform: rotate(180deg);
}

.user-page-header-container {
    // width: 60%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4%;
}

.user-page-title {
    color: $bg-senai;
    // font-size: clamp(0.8rem, .8vw, 1.5rem);
    font-size: clamp(0.9rem, 1.2vw, 1.5rem);
    font-weight: $font-medium;
}

.user-page-button:disabled{
    background-color: #9a191550;
    margin-bottom: 5%;
    cursor: auto;
}

.user-page-button {
    min-width: 12vw;
    width: auto;
    height: 2vw;
    font-size: clamp(0.4rem, 0.8vw, 1.25rem);
    padding: 0 2% 1%;
    margin-bottom: 5%;
    background-color: $bg-senai;
    border: none;

    color: white;
    border-radius: 8px;

    transition: filter 0.25s ease-out;
    cursor: pointer;

    &:hover {
        filter: brightness(0.9);
    }
}