.user-table {
    .nif {
        width: 5vw;
    }

    &[data-role="1"] {
        .user-name {
            width: 18vw !important;

            .table-header-content {
                padding: 0 !important;
            }
        }
    }

    .user-status {
        width: 8%;

        .status-wrapper {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            justify-content: center;

            .status-indicator {
                width: 0.5vw;
                height: 0.5vw;
                border-radius: 100%;

                &[data-status="1"] {
                    background-color: #209c14;
                }

                &[data-status="0"] {
                    background-color: $bg-senai;
                }
            }

            span {
                width: 80%;
                font-size: clamp(4px, 0.6vw, 12px);
            }
        }
    }

    .user-unity {
        width: 10%;
        padding: 0;
    }
    .user-role {
        width: 10%;
        padding: 0;
    }

    .user-actions {
        width: clamp(4rem, 4vw, 4.5rem);

        .edit-icon {
            width: clamp(0.5rem, 0.8vw, 1.5rem);
            height: clamp(0.5rem, 0.8vw, 1.5rem);
            margin: 0;
            cursor: pointer;
        }
    }
}

.table-item-not-send {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        width: 50%;
        color: $font-color-dark;
        // margin-left: 4px;
        font-weight: 400;
        font-size: clamp(1rem, 1.5vw, 4rem);
        text-align: center;
    }
}
