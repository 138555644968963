.completion-record-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2vw;

    .title-page-container {
        width: 100%;
        border-bottom: 3px solid #000;

        .title-page {
            font-size: 1.25rem;
        }
    }

    .proposals-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 1vw;
        padding-bottom: 1vw;

        .proposals-select-container {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: start;
        }

        .evaluation-container {
            flex: 3;
            height: 100%;
            display: flex;
            justify-content: end;

            .evaluation-card {
                width: clamp(180px, 98%, 1200px);
                height: 100%;
                padding: 20px;
                border-radius: 8px;
                background-color: #fff;
                box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 50%);
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 5px;
                }
            
                &::-webkit-scrollbar-thumb {
                    background-color: $bg-senai;
                    border-radius: 20px;
                }

                .loading-container{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}