.evaluation-card-header {
    width: 100%;
    display: flex;
    flex-direction: column;

    .evaluation-title-container {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;

        .evaluation-title {
            font-size: clamp(.6rem, 6vw, 1.4rem);
            margin-right: 12px;
        }

        hr {
            background-color: #000;
            width: calc(100% - 300px);
            height: 3px;
        }
    }
}

.evaluation-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .question-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;
        
        .registry-report-body-question-dissertative,
        .registry-report-body-question-checkbox,
        .registry-report-body-question-checkbox {
            margin-bottom: 20px;
            
            input[type="text"],
            input[type="date"] {
                width: 30vw;
                height: 3vh;
                border: none;
                border-bottom: 1px solid #c3c3c3;
                outline: none;

                &:focus {
                    border-bottom: 1px solid #9a1915;

                    transition-duration: 0.3s;
                }
            }
            
            p {
                margin-bottom: 2%;
            }
        }

        .section-title{
            margin-bottom: 20px;
        }
    }
}

.evaluation-actions-container {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .evaluation-button {
        width: auto;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $bg-senai;
        cursor: pointer;
        padding: clamp(20px, 2vw, 22px);
        color: #FFF;
        border: 0;
        border-radius: 10px;

        
        &:hover {
            filter: brightness(0.9);
        }
    }

    .evaluation-button:disabled{
        background-color: #9a191550;
        margin-bottom: 5%;
        cursor: auto;
    }
}