.form-modal-container {
    position: relative;
    padding: 2rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    max-width: 400px;
    background-color: $bg-modal;
    h3 {
        font-size: 1.1rem;
        font-weight: 400;
        color: $bg-senai;
        margin-bottom: 0.5rem;
    }
    img {
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 1rem;
        transition: opacity 0.2s;
        &:hover {
            cursor: pointer;
            opacity: 0.9;
        }
    }
    * {
        width: 100%;
    }
    input {
        border: 1px solid $input-border;
        padding: 0.7rem 0rem 0.7rem 0.7rem;
        border-radius: 2px;
        font-size: 0.9rem;
    }
    button {
        border: none;
        border-radius: 2px;
        height: 2.5rem;
        background-color: $bg-senai;
        color: $font-color;
        font-size: 1rem;
        margin-top: 0.5rem;
        transition: opacity 0.2s;
        &:hover {
            cursor: pointer;
            opacity: 0.9;
        }
    }
}