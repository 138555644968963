.tech-product-page {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding: 4.5vh 1.2vw;

    display: flex;
    justify-content: center;
    align-items: center;

    .card-container {
        width: 100%;
        height: 100%;
        padding: 10px;

        background-color: #FFF;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 8px;

        -webkit-box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, .5);
        overflow: hidden;

        .tech-product-header {
            margin: clamp(0.4rem, .8vw, 1rem);
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            height: 2vw;
            font-size: clamp(0.4rem, .8vw, 1.25rem);

            h1 {
                color: $bg-senai;
                // font-size: clamp(0.8rem, .8vw, 1.5rem);
                font-size: clamp(0.8rem, 1.2vw, 1.5rem);
                font-weight: $font-medium;
            }

            button {
                width : auto;
                padding: 0% 2%;
                height: 2vw;
                font-size: clamp(0.4rem, .8vw, 1.25rem);

                background-color: $bg-senai;
                border: none;

                // font-size: clamp(.8rem, 1.5vw, 1.25rem);
                color: white;
                border-radius: 8px;

                transition : filter 0.25s ease-out;
                cursor: pointer;
                &:hover {
                    filter: brightness(.9);
                }
            }
        }

        .tech-product-name-container {
            width: 98%;
            height: 100%;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .actions {
                display: flex;
                width: auto;
                justify-content: flex-start;
                align-items: center;

                img {
                    width: .7vw;
                    height: .7vw;
                    cursor: pointer;
                    transform: translate(90deg);
                }
            }
        }
    }


}