.create-specialty-modal {
    width: clamp(400px, 50vw, 500px);
    position: relative;

    padding: 24px;

    border: 1px solid $border-base;
    border-radius: 4px;

    background-color: $bg-modal;

    display: flex;
    gap: 24px;
    flex-direction: column;

    .close-button {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 16px;
        height: 16px;
    }

    .title {
        font-size: 18px;
        color: $bg-senai;
        font-weight: $font-medium;
        margin-bottom: 40px;
    }

    form {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .input-container {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
                font-size: 14px;
            }

            input {
                width: 75%;
                height: 32px;
                padding: 2px 8px;

                background-color: $bg-base;
                border: 0;
                border-bottom: 2px solid $font-color-dark;
                border-radius: 3px;
                font-size: 14px;
                padding-left: 2%;

                outline: none;

                font-size: clamp(4px, 1.5vw, 12px);
                color: $font-color-dark;
                font-weight: $font-medium;

                &:focus {
                    outline: none;
                    border-color: $bg-senai;
                }
            }
        }

        button {
            width: 329px;
            height: 49px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 3px;
            border: 1px solid $border-base;

            font-weight: $font-regular;
            font-size: 18px;
            color: $bg-base;

            cursor: pointer;

            background-color: $bg-senai;
            transition: background .3s ease-out;

            &:disabled {
                opacity: .5;
                cursor: not-allowed;
            }
        }

        button:hover {
            background-color: #7a0f0f;
        }
    }
}