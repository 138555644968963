.filter-container {
    min-width: 13vw;
    max-width: 13vw;
    height: 100%;

    // margin-top: 9vh !important;
    margin: 0 0.7vw;
    background-color: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1vw;
    // overflow-y: auto;
    // overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px;
        height: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background: #9a1915;
    }

    &::-webkit-scrollbar-track {
        background: #e7e7e7;
    }
}

.filter-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    span {
        color: #7e7e7e;
        font-size: clamp(0.4rem, 1.4vw, 1vw);
    }

    .filter-clear-all {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5%;
        cursor: pointer;
        span {
            color: #7e7e7e;
            font-size: clamp(0.2rem, 1.4vw, 0.6rem);
            font-weight: $font-semiBold;
        }
        img {
            width: clamp(8px, 1.4vw, 12px);
            height: clamp(8px, 1.4vw, 12px);
        }
    }
    // margin-bottom: 0.5vh;
}

.filter-main {
    max-width: 100%;
    width: 100%;
    max-height: 95%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
}

.filter-item-container {
    height: fit-content;
    // max-height: 100%;
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    &.visible {
        flex-grow: 1;
        // height: 100%;
    }
}

.filter-main-item-title {
    font-size: clamp(0.7rem, 1.2vw, 1vw);
    text-align: left !important;
    margin: min(20px, 0.5vw) 0;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3%;

    span {
        font-size: clamp(0.4rem, 1vw, 0.8vw);
        height: 100%;
        color: $font-modal-color;
        font-weight: 500;
    }
    div {
        font-size: clamp(0.4rem, 1vw, 0.8vw);
        color: $bg-senai;

        font-weight: 600;
    }
    input {
        height: 10px;
        aspect-ratio: 1;
    }

    .filter-circle {
        width: 5px;
        aspect-ratio: 1;
        border-radius: 20px;
        background-color: $bg-senai;
    }
}

.filter-main-item-body {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // border-radius: 8px;
    // max-height: calc(100% - 300px);
    flex-grow: 2;
    min-height: 25%;

    overflow-y: auto;
    width: 100%;
    gap: 20px;

    &.enterprise {
        min-height: 8%;
    }
    &.enterprise-input {
        min-height: 8%;
    }

    
    // margin: 0;
    // padding: 0;
}

.filter-item-input {
    width: 100%;
    height: clamp(32px, 2.5vw, 64px);
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    border: 1px solid $border-base;

    margin-bottom: 8px;
    input {
        padding: 0 8px;
        width: 90%;
        height: 100%;
        border: 0;
        font-size: clamp(0.4rem, 1vw, 0.6vw);
    }
    img {
        width: 1vw;
        height: 1vw;
    }
}

.filter-item-clear {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
    gap: 2%;
    cursor: pointer;

    span {
        color: $font-modal-color;
        margin-left: 4px;
        font-weight: 400;
        font-size: clamp(0.4rem, 1vw, 0.6vw);
    }
    img {
        width: clamp(8px, 1.4vw, 12px);
        height: clamp(8px, 1.4vw, 12px);
    }
}

.filter-item-not-send {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        width: 50%;
        color: $font-modal-color;
        // margin-left: 4px;
        font-weight: 400;
        font-size: clamp(0.4rem, 1vw, 0.6vw);
        text-align: center;
    }
}

.filter-item {
    // margin: 0.8vh 1vw;
    font-size: clamp(0.4rem, 1vw, 0.6vw);
    margin-bottom: 8px;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    label {
        cursor: pointer;
        width: 100%;
        color: $font-modal-color;
        margin-left: 4px;
        font-weight: 400;
    }
}

.filter-checkbox {
    display: flex;
    flex-grow: 1;
    input {
        width: 1.8vw;
        height: 1.8vh;
    }
}

.filter-input {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;

    input {
        width: 100%;
        height: 4vh;
        text-overflow: ellipsis;
        border: none;
        font-size: clamp(0.7rem, 0.8vw, 1vw);

        &:focus {
            outline: none;
        }

        &::placeholder {
            font-size: clamp(0.5rem, 0.8vw, 0.5vw);
            color: #bcbcbc;
        }
    }

    img {
        width: 7%;
    }
}

// .input-search-box {
//     border: 1px solid $border-base;
//     padding: 0 8px;
//     border-radius: 8px;
// }

.filter-item-clear-search {
    align-self: flex-end;
    color: #bcbcbc;
    font-weight: 300;
    font-size: clamp(0.5rem, 0.9vw, 0.6vw);

    span {
        margin-left: 0.3vw;
    }
}

.filter-list-searched {
    list-style: none;
    width: 100%;

    li {
        margin-top: 1.5vw;
        margin-left: 1.5vw;
        color: #bcbcbc;
        font-size: clamp(0.5rem, 1.1vw, 0.7vw);
        display: flex;
        align-items: center;

        span {
            margin-left: 1vw;
        }
    }
}

.filter-item-body-hidden,
.filter-item-input-hidden,
.filter-item-clear-hidden {
    display: none !important;
}

.filter-item-body-shown {
    width: 100%;
    display: block;
    flex-grow: 1;
}

.filter-item-body-shown-input {
    min-height: 8%;
    flex-grow: 0;
}

// .specialty-container {
//   width: 20vw;
//   height: 100%;

//   // margin-top: 9vh !important;
//   margin: 0.7vw;
//   background-color: #fff;
//   border-radius: 8px;
//   -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
//   box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;
//   padding: 1vw;
//   overflow-y: auto;
//   overflow-x: hidden;

//   &::-webkit-scrollbar {
//     width: 10px;
//     height: 50px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: #9a1915;
//   }

//   &::-webkit-scrollbar-track {
//     background: #e7e7e7;
//   }
// }

.specialty-edit-button {
    width: clamp(10px, 1vw, 20px);
    height: clamp(10px, 1vw, 20px);
    background-color: transparent;
    border: none;
    background-size: cover;
    background-image: url("../../../../../public/assets/icons/edit-red.png");
}

.specialty-delete-button {
    width: clamp(10px, 1vw, 20px);
    height: clamp(10px, 1vw, 20px);
    background-color: transparent;
    border: none;
    background-size: cover;
    background-image: url("../../../../../public/assets/icons/trash-red.png");
}
