.observation-image-container {
  position: relative;
  padding: 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  max-width: 600px;
  background-color: $bg-modal;
  h1 {
    font-size: 1.2rem;
    font-weight: 400;
    color: $bg-senai;
    margin-bottom: 0.2rem;
  }
  .image-observation {
    width: 100%;
  }
  .button-x-close-modal {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1rem;
    transition: opacity 0.2s;
    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
  .observation-text {
    color: $bg-senai;
  }
}
