
.vinculation-proposal-modal-container{
  width: $modals-width;  
  height: 92%;

  position: fixed;
  z-index: 10;

  top: 8%;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;
}

.vinculation-proposal-modal{
  width: 45vw;
  min-width: 380px;
  max-width: 16vw;
  height: auto;
  min-height: 350px;
  max-height: 900px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: $bg-base;
}

.vinculation-proposal-modal-close-container{
  width: 100%;
  height: 30px;
  padding: 0.2vh;
  display: flex;
  justify-content: flex-end;
}

.close-button{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.vinculation-proposal-modal-header{
  margin-bottom: 6px;
  margin-left: 6%;
}

.vinculation-proposal-modal-header span{
  font-family: $font-base;
  font-weight: 300;
  font-size: clamp(1.2rem, 2vw, 1.4rem);

  color: $bg-senai;
}

.vinculation-proposal-modal-body{
  width: 95%;
  height:auto;
  min-height: 30%;
  max-height: 100%;
  padding: 5vh 0 0 6%; 
  color: $font-modal-color;
  box-sizing: border-box;
  overflow: hidden;
}

.vinculation-proposal-modal-body div{
  margin-bottom: 20px;
}

.container-text-field{
  width: 100%;
  
  margin-right: 8px;

  display: flex;
  justify-content: space-between;
  input {
    width: 78%;
    height: 32px;
    background-color: $bg-base;
    border: 1px solid $border-base;
    border-radius: 3px;
  }

  select{
    width:58%;
    height: 32px;
    background-color: $bg-base;
    border: 1px solid $border-base;
    border-radius: 3px;
  }
}

.text-field-identification{
  font-size: clamp(0.6rem, 1vw, 0.9rem);
}

.select-identification{
  font-size: clamp(0.6rem, 1vw, 0.8rem);
}

.unity-group{
  display: flex;
  align-items: left;
  width: 99%;
  height: auto;
  min-height: 0.3vw;
  max-height: 10vw;
  gap: 10px;
  flex-wrap: wrap;
  padding: 0px 5px 0px 5px;
 
  div {
    width: 140px;
    display: flex;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    padding-left: 2px;

    p{
      font-size: clamp(0.6rem, 1.3vw, 1rem);

    }
  }
}


  


.remove-all{
  width: 100%;

  display: flex;
  justify-content: flex-end;

  font-size: clamp(0.6rem, 1vw, 0.8rem);
  
  span{
    cursor: pointer;
  }
}

.vinculation-proposal-modal-footer{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vinculation-proposal-modal-footer button{
  background-color: $bg-senai;
  width: 90%;
  height: 70%;
  border-radius: 3px;
  outline: none;
  border: 1px solid $border-base;
  color: $bg-base;
  font-weight: $font-thin;
}



