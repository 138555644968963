.card-list {
    max-width: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
        height: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background: #9a1915;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #E7E7E7;
        border-radius: 8px;

    }
}


.card {
    background-color: $bg-base;
    border: 1px solid $border-base;
    width: clamp(250px, 4vw, 500px);
    height: clamp(105px, 4vw, 240px);
    margin: 6px;
    padding: 4px;

    overflow: hidden;

    transition: .4s;

    &:hover {
        background-color: #e8eaea;
    }
}

.card-title {
    color: $bg-senai;
    margin: 8px;
    margin-bottom: 12px;
    font-weight: $font-medium;
    text-overflow: ellipsis;
    font-size: clamp(8px, 4vw, 16px);
    width: 100%;

    display: flex;
    justify-content: space-between;

    img {
        width: 16px;
        height: 16px;
        cursor: pointer;

    }
}


.card-info {
    margin: 8px;
    font-weight: $font-regular;
    font-size: clamp(10px, .6vw, 14px);
    white-space: break;
    max-width: 100%;

}




// @media (min-width:200px) and (max-width:560px){
//     .enterprise-card-container{
//         width: 69vw;
//         height: 11vh;
//     }
//     .enterprise-card-name {
//         font-size: 23px;
//     }

//     .enterprise-card-cnpj {
//         font-size: 15px;
//     }

//     .enterprise-card-city {
//         font-size: 15px;
//     }
// }

// @media (min-width:561px) and (max-width:700px){
//     .enterprise-card-container{
//         width:35vw;
//         height: 11vh;
//     }
//     .enterprise-card-name {
//         font-size: 20px;
//     }

//     .enterprise-card-cnpj {
//         font-size: 13px;
//     }

//     .enterprise-card-city {
//         font-size: 13px;
//     }
// }

// @media (min-width:701px) and (max-width:900px){
//     .enterprise-card-container{
//         width:35vw;
//         height: 11vh;
//     }
//     .enterprise-card-name {
//         font-size: 19px;
//     }

//     .enterprise-card-cnpj {
//         font-size: 12px;
//     }

//     .enterprise-card-city {
//         font-size: 12px;
//     }
// }

// @media (min-width:901px) and (max-width:1090px){
//     .enterprise-card-container{
//         width: 24vw;
//         height: 10vh;
//     }
//     .enterprise-card-name {
//         font-size: 18px;
//     }

//     .enterprise-card-cnpj {
//         font-size: 12px;
//     }

//     .enterprise-card-city {
//         font-size: 12px;
//     }
// }

// @media (min-width:1091px) and (max-width:1200px){
//     .enterprise-card-container{
//         width: 24vw;
//         height: 10vh;
//     }
//     .enterprise-card-name {
//         font-size: 17px;
//     }

//     .enterprise-card-cnpj {
//         font-size: 12px;
//     }

//     .enterprise-card-city {
//         font-size: 12px;
//     }
// }

// @media (min-width:1201px) and (max-width:1800px){
//     .enterprise-card-container{
//         width: 24vw;
//         height: 10vh;
//     }
//     .enterprise-card-name {
//         font-size: 16px;
//     }

//     .enterprise-card-cnpj {
//         font-size: 12px;
//     }

//     .enterprise-card-city {
//         font-size: 12px;
//     }
// }

// @media (min-width:1801px) and (max-width:1900px){
//     .enterprise-card-container{
//         width: 25vw;
//         height: 10vh;
//     }
//     .enterprise-card-name {
//         font-size: 16px;
//     }

//     .enterprise-card-cnpj {
//         font-size: 12px;
//     }

//     .enterprise-card-city {
//         font-size: 12px;
//     }
// }