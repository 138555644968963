.enterprise-transformation-stage-card-container {
    width: 7vw;
    height: 5vw;
    // max-height: 70px;

    border: 1px solid #333;
    border-radius: 2px;
    display: flex;
    flex-direction: column;

    // justify-content: space-around;

    background-color: rgb(245, 245, 245);
}

.enterprise-transformation-stage-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.enterprise-transformation-stage-card-header-step {
    font-size: clamp(8px, 1vw, 16px);
    text-align: center;
    font-weight: 600;
    align-self: flex-start;
}

.enterprise-transformation-stage-card-header-step-title {
    font-size: clamp(4px, 0.2vw, 18px);
    text-align: center;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    p {
        font-weight: 600;
    }
}

.enterprise-transformation-stage-card-footer {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-size: clamp(4px, 0.5vw, 14px);
}

@media (max-width: 1600px) {
    // .enterprise-transformation-stage-card-header-image {
    //     width: 50%;
    //     max-width: 32px;
    //     max-height: 32px;
    // }
}

.enterprise-transformation-stage-current {
    width: 7vw;
    height: 5vw;
    position: absolute;
    z-index: 2;
    background-color: #9a191540;
}

.enterprise-transformation-stage-current-transparent {
    width: 7vw;
    height: 5vw;
    position: absolute;
    z-index: 2;
    background-color: transparent;
}
