.transformation-card-side-center {
    width: 10%;
    height: 100vh;
    max-height: 65vh;
}

.transformation-card-side-center-second-floor,
.transformation-card-side-center-first-floor {
    width: 100%;
    height: 37%;


    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.transformation-card-side-center-second-floor {
    background-color: #e7e7e7;
}

.transformation-card-side-center-first-floor {
    background-color: #dedede;
}

.transformation-card-side-center-solo-floor {
    width: 100%;
    height: 36.2%;

    background-color: #c4c4c4;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.transformation-card-side-center-solo-floor-top {
    width: 100%;
    height: 30%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.transformation-card-side-center-solo-floor-top-line {
    width: .5px;
    height: 100%;
    border: 0;
    background-color: #2f2f2f;
}



.transformation-card-side-center-solo-floor-center {
    width: 100%;
    height: 40%;
    
    display: flex;
    align-items: center;
    justify-content: center;

}


.transformation-card-side-center-solo-floor-bottom {
    width: 100%;
    height: 30%;

    display: flex;
    align-items: center;
    justify-content: center;

}



.transformation-card-side-center-solo-floor-center-left,
.transformation-card-side-center-solo-floor-center-right {

    width: 28%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: right;

}

.transformation-card-side-center-solo-floor-center-center {
    position: relative;
    width: 50%;
    height: 100%;
    min-width: 50px;
    // min-height: 68px;

}

.transformation-card-side-center-solo-floor-center-left-line {
    width: 100%;
    height: 1px;

    background-color: black;
}

.transformation-card-side-center-solo-floor-center-right {

    width: 25%;
    height: 100%;

}

// @media (max-width:950px) {
//     .transformation-card-side-center-solo-floor-center-center {
//         min-width: 20px;
//         // max-height: 100px;
//     }

//     .transformation-card-side-center-solo-floor-top {
//         height: 30%;
//     }

//     .transformation-card-side-center-solo-floor-center {
//         height: 40%;
//     }

//     .transformation-card-side-center-solo-floor-bottom {
//         height: 30%;
//     }
// }


@media (max-width:950px) {
    .transformation-card-side-center-solo-floor-center-center {
        min-width: 20px;
        // max-height: 100px;
    }

    .transformation-card-side-center-solo-floor-top {
        height: 35%;
    }

    .transformation-card-side-center-solo-floor-center {
        height: 30%;
    }

    .transformation-card-side-center-solo-floor-bottom {
        height: 35%;
    }
}

@media (max-width:570px) {
    .transformation-card-side-center-solo-floor-center-center {
        min-width: 20px;
        // max-height: 100px;
    }

    .transformation-card-side-center-solo-floor-top {
        height: 40%;
    }

    .transformation-card-side-center-solo-floor-center {
        height: 20%;
    }

    .transformation-card-side-center-solo-floor-bottom {
        height: 40%;
    }
}