.modal-edit-specialty-container {
  width: 30%;
  height: 20%;

  background-color: $bg-base;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}


.modal-edit-specialty-title {
  margin: 0 0 5% 5%;
  font-size: 18px;
  color: $bg-senai;
  font-weight: $font-regular;
}

.modal-edit-specialty-form-input {
  width: 95%;
  height: 20%;
  margin: 0 0 5% 5%;
  gap: 5%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    font-size: 16px;
    color: $bg-senai;
    font-weight: $font-regular;
  }

  input {
    width: 70%;
    height: 90%;
    background-color: $bg-base;
    border: 1px solid $border-base;
    border-radius: 3px;
    font-size: 14px;
    padding-left: 2%;
  }
}

.modal-edit-specialty-button-container {
  width: 100%;
  height: 25%;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 50%;
    height: 90%;

    background-color: $bg-senai;
    color: $bg-base;
    transition: 0.3s;
    border: 1px solid $border-base;
    color: $bg-base;
    font-weight: $font-regular;

    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      background-color: #7a0f0f;
      color: #d0d0d0;
    }
  }
}
