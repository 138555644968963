.dashboard-container{
    display: flex;
    flex-direction: row;
}

.left-dashboard-container{
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    width: 74.25vw;
    height: 100%;
}

.infos-container{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: bisque;
}

.graphs-container{
    width: 100%;
    height: 80%;
    background-color: blueviolet;
}

.right-dashboard-container{
    background-color: black;
    width: 21vw;
    height: 100%;
}

.info{
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 3  00;
    background-color: #fff;
}

.info-text{
    font-size: 30px;
    text-align: center;
}
