.specialties-page {
    height: 100%;
    width: 100%;
    padding: 3vh 1.2vw;

    display: flex;
    justify-content: center;
    align-items: center;

    .card-container {
        height: 100%;
        width: 100%;
        padding: 1.5% 1.5%;

        border-radius: 12px;

        background-color: white;

        display: flex;
        flex-direction: column;
        // gap: clamp(24px, 5vh, 48px);

        -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

        -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

        -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

        #load-warning {
            color: $input-border;
            font-size: clamp(.4rem, 4vw, .8rem);
            font-weight: $font-light;
        }

        header {
            width: 100%;

            display: flex;
            justify-content: space-between;

            margin-bottom: 1%;

            h2 {
                color: $bg-senai;
                font-size: clamp(.8rem, 4vw, 1.75rem);
                font-weight: $font-regular;
            }

            button {
                min-width: 12vw;
                width: auto;
                height: 2vw;
                font-size: clamp(0.4rem, 0.8vw, 1.25rem);
                padding: 0 2%;
                background-color: $bg-senai;
                border: none;

                color: white;
                border-radius: 8px;

                transition: filter 0.25s ease-out;
                cursor: pointer;

                &:hover {
                    filter: brightness(0.9);
                }
            }
        }

        .specialty-cell-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .actions {
                display: flex;
                flex-direction: row;
                gap: clamp(1rem, 4vw, 2rem);

                img {
                    width: clamp(16px, 1vw, 24px);
                    height: clamp(16px, 1vw, 24px);
                    margin: 0;
                }
            }
        }
    }
}