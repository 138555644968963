.evaluations-form {

    width: 100%;
    height: 100%;
    display: flex;
    padding: 1%;
    font-size: clamp(6px,.8vw,24px);
    input,
    select {
        border: 0px solid #0000;
        border-bottom: 1PX solid #bcbcbc;
        color: #7e7e7e;
        font-size: clamp(6px,.8vw,24px);
    }

}

.evaluations-form-left-side {
  
    width: 75%;
    height: 100%;
    padding-left: 1%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 5%;
    justify-content: center;
}

.evaluations-form-right-side {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    button{
        width: 80%;
        height: 12%;
        background-color: $bg-senai;
        border: 0px;
        color: #fff;
        font-size: clamp(15px,.8vw,32px);
        border-radius: 5px;
        transition: .5s;
    }
    button:hover{

        cursor: pointer;
        background-color: #751714;
    }
}

.evaluations-form-header {
    display: flex;
    width: 100%;
    height: 10%;
    justify-content: space-between;

    :nth-child(1){width: 40%;}
    :nth-child(2){width: 30%;}
    :nth-child(3){
        width: 10%;
        text-align: center;
    }
}

.evaluations-form-statement {
    width: 100%;
    height: 10%;
}

.evaluations-form-alternative-container {
  
    width: 100%;
    height: 60%;


}

.evaluations-form-alternative-header {
  
    width: 100%;
    height: 16%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $bg-senai;
    

}

.evaluations-form-alternative-header-slider {
    width: 20%;
    display: flex;
    color: #7e7e7e;
    justify-content: space-around;
    align-items: center;
}


.evaluations-form-answers-container {

    width: 100%;
    height: 40%;
    display: flex;

    justify-content: center;
    align-items: center;


}

.evaluations-form-answers-delete{
    width: 3%;
    height: 30%;

    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
      
    }
}

.evaluations-form-alternative {
    background-color: #fff;
    margin-bottom: 1%;
 
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    :nth-child(2) {
        width: 100%;
        height: 40%;
        text-align: left;
  
    }

}
.evaluations-form-alternative-name-container {
 
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-between;

    :nth-child(1) {
        width: 93%;
        height: 80%;
        margin-right: 1%;
    }

    :nth-child(2) {
        width: 6%;
        height: 80%;
        text-align: center;
  
    }
}

.answers-container{
    width: 100%;
    height: 75%;
    overflow: scroll;
}

.evaluations-form-alternative-actions {

    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    gap: 2%;
    justify-content: flex-end;
}

.evaluations-form-right-side-header {

    width: 100%;
    height: 37%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
        select {
        height: 30%;
        width: 80%;
        border: 0px solid #0000;
        border-bottom: 1px solid #bcbcbc;
        color: #7e7e7e;
        
        font-size: clamp(6px,.8vw,24px);
    }
}

.evaluations-form-alternative-header-slider-right{
    width: 70%;
    display: flex;
    color: #7e7e7e;
    justify-content: space-around;
    align-items: center;
}

.evaluations-form-delete-container {

    width: 100%;
    height: 8%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
        height: 65%;
        width: 9%;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s;
    }

    img  :hover {
            background-color: #75171420;
        }

}