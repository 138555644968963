.modal-create-user-wrapper {
    background-color: rgba(0, 0, 0, 0.6);
    width: $modals-width;
    height: 92%;
    z-index: 22;
    position: absolute;
    top: 8%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-create-user-container {
    width: 60vw;
    padding: 0.3vw;
    height: auto;
    min-height: 300px;
    border: 1px solid $border-base;
    border-radius: 4px;
    background-color: $bg-modal;
    display: flex;
    flex-direction: column;
}

.modal-create-user-close-container {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
}

.close-button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modal-create-user-title {
    margin: 0 0 0 30px;
    font-size: 18px;
    color: $bg-senai;
    font-weight: $font-regular;
}

.modal-create-user-form {
    margin: 20px 30px;
    display: flex;
    // align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: auto;
    width: auto;

    form {
        min-width: 60%;
        width: auto;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    // button {
    //   background-color: $bg-senai;
    //   width: 200px;
    //   height: 49px;
    //   border-radius: 3px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   margin-left: 24px;
    //   margin-top: 8px;
    //   margin-bottom: 20px;
    //   border: 1px solid $border-base;
    //   color: $bg-base;
    //   font-weight: $font-thin;
    //   transition: 0.3s;
    // }

    // button:hover {
    //   cursor: pointer;
    //   background-color: #7a0f0f;
    // }

    button {
        width: 329px;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 3px;
        border: 1px solid $border-base;

        font-weight: $font-regular;
        font-size: 18px;
        color: $bg-base;

        cursor: pointer;

        background-color: $bg-senai;
        transition: background 0.3s ease-out;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

.modal-create-user-form-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 1%;

    code {
        float: left;
        font-weight: $font-regular;
        font-size: 14px;
    }

    label {
        margin-right: 20px;
        width: 20%;
        font-weight: $font-regular;
        font-size: 14px;
    }

    select {
        // max-width: 240p
        width: 80%;
        height: 32px;
        background-color: $bg-base;
        border: 1px solid $border-base;
        border-radius: 3px;
    }

    input {
        width: 80%;
        height: 32px;
        background-color: $bg-base;
        border: 0;
        border-bottom: 2px solid $font-color-dark;
        border-radius: 3px;
        font-size: 14px;
        padding-left: 2%;

        outline: none;

        font-size: clamp(4px, 1.5vw, 12px);
        color: $font-color-dark;
        font-weight: $font-medium;

        &:focus {
            outline: none;
            border-color: $bg-senai;
        }
    }

    .modal-create-user-name {
        width: 80%;
        font-weight: $font-regular;
        font-size: 14px;
    }
    margin-bottom: 12px;
}
.create-user-button-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.modal-create-user-form-select-none {
    display: none;
}

.modal-create-user-form-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    min-height: 40px;
    margin-bottom: 12px;
    width: 100%;

    label {
        margin-right: 20px;
        font-weight: $font-thin;
        font-size: 14px;
    }

    select {
        max-width: 240px;
        width: 240px;
        height: 32px;
        background-color: $bg-base;
        border: 1px solid $border-base;
        border-radius: 3px;
        padding-left: 8px;
    }
}

.modal-create-user-form-select-camp {
    display: flex;
    justify-content: space-between;
    align-items: left;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 5px;
    padding: 0px 10px 0px 10px;
}

.modal-create-user-form-select-camp-chose {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 3px;
}

.modal-create-user-form-select-camp-chose-margin {
    width: 35%;
    height: 20px;
}

.modal-create-user-form-select-camp-chose-button {
    background-color: #c2c2c200 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px !important;
    border: 1px solid #2f2f2f !important;
    padding: 3px !important;
    width: 20px !important;
    height: 20px !important;
    margin: 0px !important;
    transition: 0.7s;

    img {
        width: 100%;
        height: 100%;
    }
}

.modal-create-user-form-select-camp-chose-button:hover {
    margin: 0px !important;
    background-color: #c2c2c250 !important;

    img {
        width: 120%;
        height: 120%;
    }
}

.modal-create-user-form-select-camp-chose-container {
    max-width: 65%;
    width: 65%;
    font-size: 12px;
    display: flex;
    gap: 6%;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    div {
        display: flex;
        max-width: 40%;
        width: 40%;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        background-color: #f4f6f6;
        border-radius: 3px;
        border: 1px solid #2f2f2f;
        padding: 5px 5px;
        margin-right: 15px;
        margin-bottom: 5px;
    }
}
