.collapsible-menu-container {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;

}

.collapsible-item-container {
    padding : 6px 24px;
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    p,
    span {
        font-size: clamp(.5rem, .75vw, 1rem);
        font-weight: $font-medium;
        color: $bg-senai;

        position: relative;

        &::after {
            content: "";

            width: 0%;
            height: 1px;
            background-color: $bg-senai;

            position: absolute;
            bottom: -2px;
            left: 0;

            transition: width 0.25s ease-out;
        }
    }

    &:hover span::after,
    .active span::after,
    &:hover p::after,
    .active p::after {
        width: 100%;
    }


}

.collapsible-menu-item {
    height: auto;
    min-height: 3.7vh;
    cursor: pointer;
    

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;

    span {
        font-size: clamp(0.4rem, .6vw, 1.2rem);
        font-weight: $font-medium;
        color: $bg-senai;

        position: relative;


        &::after {
            content: "";

            width: 0%;
            height: 1px;
            background-color: $bg-senai;

            position: absolute;
            bottom: -2px;
            left: 0;

            transition: width 0.25s ease-out;
        }
    }

    &:hover span::after {
        width: 95%;
    }


}

.collapsible-menu-container-none {
    height: auto;
    min-height: clamp(30px, 3vw, 90px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 6%;
    cursor: pointer;

    span {
        font-size: clamp(.4rem, 1.25vw, 1rem);
        font-weight: $font-medium;
        color: $bg-senai;

        position: relative;

    }
}

.collapsible-menu-icon {
    margin-left: auto;
}