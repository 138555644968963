.management-page-container {
    background-color: $bg-base;
    height: 93%;


    display: flex;
    justify-content: center;
    align-items: center;

    .bg {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        opacity: .15;
        img {
            width: clamp(300px, 30vw,600px);
            height: min-content;
        }
    }
}