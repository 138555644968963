.create-question-answer-container {
  width: 100%;
  height: 70%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.create-question-answer-header {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-question-answer-title {
  width: 10%;
  height: 100%;
  font-size: clamp(4px, 1.5vw, 16px);
  color: $bg-senai;
  font-weight: $font-medium;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.create-question-answer-button {
  width: 20%;
  height: 60%;
  background-color: $bg-senai;
  border-radius: 5px;
  border: none;

  font-size: clamp(4px, 1.5vw, 12px);
  color: $bg-base;
  font-weight: $font-regular;

  cursor: pointer;
  transition: 0.4s;

  &:hover {
    width: 25%;
    height: 65%;

    box-shadow: 5px 5px 10px $font-modal-color;
    font-size: clamp(6px, 1.5vw, 14px);
  }
}

.answers-container {
  width: 100%;
  height: 90%;

  overflow-y: scroll;
}

.answer-container {
  width: 100%;
  height: auto;

  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.answer-top-container {
  width: 100%;
  height: 50%;

  display: flex;
  gap: 1%;

  .answer-top-number {
    width: 2%;
    height: 100%;

    font-size: clamp(4px, 1.5vw, 16px);
    color: $bg-senai;
    font-weight: $font-medium;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .answer-top-name {
    width: 85%;
    height: 100%;
    gap: 1%;

    input {
      width: 92%;
      height: 50%;
    }
  }

  .answer-top-weight {
    width: 7%;
    height: 100%;
    gap: 15%;

    input {
      width: 92%;
      height: 50%;
    }
  }

  .answer-top-name,
  .answer-top-weight {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input {
      background-color: $bg-base;
      border: 0;
      border-bottom: 2px solid $font-color-dark;
      border-radius: 3px;
      font-size: 14px;
      padding-left: 1%;

      font-size: clamp(4px, 1.5vw, 12px);
      color: $font-color-dark;
      font-weight: $font-medium;
    }
  }



}

.answer-ramification-container {
  width: 8%;
  height: 10%;

  display: flex;
  align-items: center;
  justify-content: center;

  .answer-top-delete {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}

.answer-bottom-container {
  width: 100%;
  height: 50%;

  display: flex;
  gap: 1%;

  .answer-bottom-order-container {
    height: 100%;
    width: 2%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .answer-bottom-image {
      height: 30%;
      width: 80%;
      cursor: pointer;

      &.up {
        transform: rotate(90deg);
      }

      &.down {
        transform: rotate(270deg);
      }
    }
  }

  .answer-bottom-description {
    width: 100%;
    height: 100%;

    input {
      width: 90%;
      height: 50%;
      background-color: $bg-base;
      border: 0;
      border-bottom: 2px solid $font-color-dark;
      border-radius: 3px;
      font-size: 14px;
      padding-left: 1%;

      font-size: clamp(4px, 1.5vw, 12px);
      color: $font-color-dark;
      font-weight: $font-medium;
    }
  }
}

$border: #ddd;
$border-hover: rgb(71, 71, 71);
$bg-hover: rgba(0, 0, 0, .1);
$text: rgba(102, 102, 102, 1);
$text-hover: #000;
$ident: 30px;
$left: -($ident);

ol {
  margin-left: $ident;
  counter-reset: item;
}

.wtree {
  li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative;

    &:before {
      content: "";
      counter-increment: item;
      position: absolute;
      top: -10px;
      left: $left;
      border-left: 1px solid $border;
      border-bottom: 1px solid $border;
      width: $ident;
      height: 15px;
    }

    &:after {
      position: absolute;
      content: "";
      top: 5px;
      left: $left;
      border-left: 1px solid $border;
      border-top: 1px solid $border;
      width: $ident;
      height: 100%;
    }

    &:last-child:after {
      display: none;
    }

    span {
      display: block;
      border-left: 1px solid $border;
      padding: 10px;
      color: $text;
      text-decoration: none;
    }

    select {
      width: 15vw;
      border: none;
      border-bottom: 2px solid $font-color-dark;
      background-color: transparent;
      margin-top: 1%;
    }
  }
}

.wtree {
  li {
    span {

      &:hover,
      &:focus {
        color: $text-hover;
        // border: 1px solid $border-hover;

        &+ol {
          li {
            span {
              color: $text-hover;
              // border: 1px solid $border-hover;
            }
          }
        }
      }

      &:hover+ol,
      &:focus+ol {
        li {

          &:after,
          &:before {
            border-color: $border-hover;
          }
        }
      }
    }
  }
}