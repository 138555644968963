.evaluations-page-container {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    height: 100vh;
}
.evaluation-register-container,
.macro-register-container,
.criterion-register-container {
    width: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    height: 90%;
}

.macro-register-container {
    height: 85%;
}

.criterion-register-container {
    height: 80%;
}

.evaluation-register-container-none,
.macro-register-container-none,
.criterion-register-container-none,
.questions-container-none,
.add-icon-none {
    display: none;
}

.register-evaluation {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 5px 0px 2px 0px 15px #000;
    padding: 1% 3%;
    max-width: 600px;
    width: 60%;
    height: 12%;

    display: flex;
    align-items: flex-start;
    gap: 10%;
    justify-content: left;
    flex-direction: column;

    p {
        font-size: clamp(8px, 1vw, 28px);
        font-weight: $font-regular;
        color: $bg-senai;
    }

    .register-evaluation-container {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 30%;
            height: 100%;
            background-color: $bg-senai;
            border: 0px;
            color: #fff;
            font-size: clamp(15px, 0.8vw, 32px);
            border-radius: 5px;
            transition: 0.5s;
        }
        button:hover {
            cursor: pointer;
            background-color: #751714;
        }
    }

    .evaluation-input {
        height: 100%;
        border: 0px solid #0000;
        border-bottom: 1px solid #bcbcbc;
        color: #7e7e7e;
        font-size: clamp(6px, 0.8vw, 24px);
    }
}

.selected-container,
.reduce-selected-container {
    background-color: #e8e8e8;
    width: 100%;
    display: flex;
    padding-left: 3%;
    gap: 3%;
    align-items: center;
    justify-content: left;
    height: 92%;
}
.reduce-selected-container {
    width: 35%;
}

.registration-container-none {
    display: none;
}

.registration-container {
    background-color: #e8e8e8;
    width: 65%;
    height: 92%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.questions-container {
    border-radius: 7px;
    box-shadow: 5px 0px 2px 0px 15px #000;
    width: 95%;
    height: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.questions-container-header {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 1px 7px 10px #aaa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    width: 95%;
    height: 12%;
    margin-bottom: 2%;
    color: $bg-senai;
    font-size: clamp(12px, 1vw, 32px);

    .questions-container-header-actions {
        width: 7%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 50%;
        display: flex;
        gap: 10%;
        .add-icon {
            width: 1vw;
            height: 1vw;
        }
    }
}

.questions-container-question-container {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8%;
    padding-bottom: 2%;
    overflow-y: scroll;
}

.questions-container-question {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 1px 7px 10px #aaa;
    width: 95%;

    min-height: 60%;
}

.evaluations-container,
.macros-container,
.criteria-container {
    overflow-y: auto;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 5px 0px 2px 0px 15px #000;
    max-width: 305px;
    width: 30%;
    height: 90%;

    &::-webkit-scrollbar {
        width: 10px;
        height: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background: #9a1915;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #e7e7e7;
        border-radius: 8px;
    }
}

.evaluations-header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    p {
        font-size: clamp(8px, 1vw, 28px);
        font-weight: $font-regular;
        color: $bg-senai;
    }
}

.add-icon {
    width: 0.8vw;
    height: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .add-icon-horizontal {
        background-color: #c1c1c1;
        width: 100%;
        height: 10%;
        position: relative;
    }

    .add-icon-vertical {
        background-color: #c1c1c1;
        height: 100%;
        width: 10%;
        position: relative;
        left: 50%;
    }
}

.evaluation-container-card {
    width: 100%;
    min-height: 5%;
    height: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: 0.8s;

    .evaluation-actions-container {
        margin-left: 2%;
        width: 18%;
        height: 100%;

        gap: 10%;

        display: flex;

        .evaluation-actions-image-container {
            cursor: pointer;
            width: 40%;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            .evaluation-actions-image-container-trash {
                width: 0.5vw;
                height: 0.5vw;

                max-height: 20px;
                background-image: url("../../../../../public/assets/icons/trash-red.png");
                background-size: cover;
            }
            .evaluation-actions-image-container-edit {
                width: 0.5vw;
                height: 0.5vw;

                max-height: 20px;
                background-image: url("../../../../../public/assets/icons/edit-red.png");
                background-size: cover;
            }
        }
    }
}

.evaluation-container-card:hover {
    background-color: $bg-senai;

    .evaluation-actions-container {
        .evaluation-actions-image-container {
            .evaluation-actions-image-container-trash {
                background-image: url("../../../../../public/assets/icons/trash-white.png");
            }
            .evaluation-actions-image-container-edit {
                background-image: url("../../../../../public/assets/icons/edit-white.png");
            }
        }
    }
}

.evaluations-content {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;

    .evaluations-content-card {
        width: 80%;
        min-height: 100%;
        height: auto;
        display: flex;
        gap: 3%;
        align-items: center;
        justify-content: space-between;
        padding: 1vh 0.2vw;
        transition: 0.8s;

        input {
            width: 90%;
            font-size: clamp(6px, 0.7vw, 14px);
            text-align: left;
            font-weight: $font-regular;
            color: $bg-senai;
            border: 0px;
            transition: 0.8s;
            outline: 0px;
            cursor: pointer;

            &:disabled {
                border: 0px;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }

        .imageContainer {
            min-height: 15px;
            min-width: 15px;
            height: 1vw;
            width: 1vw;

            background-image: url("../../../../../public/assets/icons/right-arrow-red.png");
            background-size: cover;
        }
    }

    .evaluations-content-card:hover {
        background-color: $bg-senai;
        cursor: pointer;

        .imageContainer {
            background-image: url("../../../../../public/assets/icons/right-arrow-white.png");
        }

        input {
            color: #fff;
            background-color: $bg-senai;
        }
    }
}

.evaluations-container-card-edited {
    width: 100%;
    min-height: 5%;
    height: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: 0.8s;

    background-color: $bg-senai;

    input {
        color: $bg-base;
        background-color: $bg-senai;
    }

    .imageContainer {
        background-image: url("../../../../../public/assets/icons/right-arrow-white.png");
    }

    .evaluations-content-card {
        width: 80%;
        min-height: 100%;
        height: auto;
        display: flex;
        gap: 3%;
        align-items: center;
        justify-content: space-between;
        padding: 1vh 0.2vw;
        transition: 0.8s;

        input {
            width: 90%;
            font-size: clamp(6px, 0.7vw, 14px);
            text-align: left;
            font-weight: $font-regular;
            color: $bg-base;
            background-color: $bg-senai;
            border: 0px;
            border-bottom: 1px solid $bg-base;
            transition: 0.8s;
            outline: 0px;
            cursor: text;
        }
    }

    .imageContainer {
        min-height: 15px;
        min-width: 15px;
        height: 1vw;
        width: 1vw;

        background-image: url("../../../../../public/assets/icons/right-arrow-white.png");
        background-size: cover;
    }

    .evaluation-actions-container {
        margin-left: 2%;
        width: 18%;
        height: 100%;

        gap: 10%;

        display: flex;

        .evaluation-actions-image-container {
            cursor: pointer;
            width: 40%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .evaluation-actions-image-container-trash {
                width: 0.5vw;
                height: 0.5vw;
                max-height: 20px;
                background-image: url("../../../../../public/assets/icons/cancel-white.png");
                background-size: cover;
            }
            .evaluation-actions-image-container-edit {
                width: 0.5vw;
                height: 0.5vw;
                max-height: 20px;
                background-image: url("../../../../../public/assets/icons/confirm-white.png");
                background-size: cover;
            }
        }
    }
}

.macros-container {
    background-color: #fff;
    width: 30%;
    height: 85%;
}

.macros-container-none {
    display: none;
}

.criteria-container {
    background-color: #fff;
    width: 30%;
    height: 80%;
}

.criteria-container-none {
    display: none;
}
