.report-registry-menu-container {
    width: clamp(150px, 100%, 350px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $bg-senai;
        border-radius: 20px;
    }

    .proposal-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        input {
            background-color: red !important;
            color: red !important;
        }

        .proposal-referent {
            font-size: clamp(.5rem, 1.5vw, .8rem);
            font-weight: $font-bold;
        }

        .proposal-evaluation-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 1vw;
            padding-top: 10px;
            
            .proposal-evaluation {
                font-size: clamp(.4rem, 1.5vw, .8rem);
                cursor: pointer;
                
                &:hover{
                    color: $bg-senai;
                }
            }

            .proposal-evaluation-common {
                font-size: clamp(.4rem, 1.5vw, .8rem);
            }

            .proposal-evaluation-date{
                font-size: clamp(.4rem, 1.5vw, .7rem);
                font-style: italic;
                .container-check-date{
                    position: relative;
                }
                .container-check-date input {
                    position: absolute;
                    opacity: 0;
                    height: 0;
                    width: 0;
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                .container-check-date input:checked ~ .checkmark:after {
                    display: block;
                }
                .container-check-date .checkmark:after {
                    left: 9px;
                    top: 0px;
                    width: 4px;
                    height: 8px;
                    border: solid green;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
            }
        }
    }
}