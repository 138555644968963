.add-contact-modal-container{
  width: $modals-width;  
  height: 92%;

  position: fixed;
  z-index: 99;

  right: 0;
  top: 8%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;
}

.add-contact-modal{
  width: 406px;
  height: 30.5vh;
  min-height:350px;

  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;  
}

.add-contact-modal-close-container{
  display: flex;
  justify-content: flex-end;

  padding-right: 5%;
  padding-top: 2%;

  width: 100%;

  span{
    font-size: 20px;
    color: $bg-senai;
  }
}

.add-contact-modal-header{
  width: 90%;
  padding-left: 15%;
  
  margin-bottom: 6%;

  p{
    color: $bg-senai;
    font-size: 16px;;
  }

  span{
    color: $bg-senai;
    font-size: 20px;
  }
}

.add-contact-modal-body{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  padding-left: 9%;

  width: 90%;
  height: 60%;

  form{
    height: 100%;

    gap: 7%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    input[type="text"] {
      padding: 0.5rem 2rem;
      width: 72%;
  
    }

    input[type="email"] {
      padding: 0.5rem 2rem;
      width: 72%;
  
    }

    input[type="tel"] {
      padding: 0.5rem 2rem;
      width: 72%;
  
    }

    .span-container{
      width: 100%;
      padding-left: 20px;

      span{
        font-weight: 100;

        font-size: 12px;

        color: $font-modal-color;
      }
    }

    button{
      border: none;
   
      padding: 1rem 6rem;
      border-radius: 4px;
      
      font-size: 1rem;
    
      background: $bg-senai;
      color: $bg-base;
    }
  }
}
