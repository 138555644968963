.proposals-modal-container {
  width: $modals-width;
  height: 92%;

  position: fixed;
  z-index: 99;

  right: 0;
  top: 8%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;
}

.proposals-modal {
  width: 45vw;
  min-width: 350px;
  height: 25vh;
  padding: .3%;

  background-color: $bg-modal;

  display: flex;
  flex-direction: column;
  align-items: center;

}

.proposals-modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.10%;

  color: $bg-senai;
  font-weight: 500;
}

.proposals-modal-close-container {
  display: flex;
  justify-content: flex-end;

  padding-right: 3%;
  padding-top: 1%;

  width: 100%;

  span {
    font-size: 20px;
    color: $bg-senai;
  }
}

.proposals-modal-body {
  width: inherit;
  min-width: 350px;

  display: flex;
  flex-direction: column;
}

.proposals-modal-actions-button-container {
  display: flex;
  width: 70%;
  height: 100%;
  gap: 10%;

  button {
    border: none;
    width: 75%;
    height: 100%;

    padding: 0.5vw 0.1vw;
    border-radius: 4px;

    font-size: 15px;
    font-weight: 200;

    background: $bg-senai;
    color: $bg-base;
  }
}

.proposals-modal-actions-container {
  width: 100%;
  padding-left: 3%;
  gap: 3%;
  display: flex;
  justify-content: space-between;
}

.proposals-modal-select-all-container {
  width: 15%;
  gap: 5%;
  padding-left: .8%;

  display: none;

  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    font-weight: 300;
  }
}

.proposal-modal-category-container {
  width: 100%;

  display: flex;
  align-items: flex-end;

  margin-top: 20px;


}

.proposal-modal-category {
  width: fit-content;
  height: 90%;

  padding: 0.7vw;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: width 0.25s ease-out;

  border-radius: 5px 5px 0 0;

  background-color: $bg-senai;

  color: #fff;

  cursor: pointer;

  p {
    font-size: 12px;
    font-weight: 500;
  }

  &.active-category {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;

    color: $bg-senai;
    height: 105%;
  }
}


.proposals-modal-cards-container {
  width: inherit;
  min-width: 350px;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
}

.proposal-modal-cards {
  width: 96%;
  height: 92%;

  overflow-y: scroll;
  gap: 13px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: flex-start;

  &::-webkit-scrollbar {
    width: 10px;
    height: 50px;

  }

  &::-webkit-scrollbar-thumb {
    background: #9a1915;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #FFF;
  }

  .card {
    width: 190px;

    background-color: white;

    padding: 6px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    border-width: 1.3px;
    border-style: solid;
    border-color: $border-base;
    border-radius: 4px;

    gap: 4px;

    .card-header {
      width: 100%;

      padding-right: 6px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      span {
        margin-right: 6px;

        color: $bg-senai;

        font-size: 12px;
        font-weight: 300;
      }
    }

    p {
      font-size: 10px;
    }
  }
}