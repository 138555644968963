.confirmation-modal-container{
  width: $modals-width;  
  height: 92%;

  position: fixed;
  z-index: 99;

  right: 0;
  top: 8%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;
}

.confirmation-modal{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  background-color: $bg-base;
}

.confirmation-modal-body{
  margin-bottom: 30px;
}

.confirmation-modal-header span{
  font-family: $font-base;
  font-weight: 300;
  font-size: 120%;
  color: $bg-senai;
}

.confirmation-modal-footer button {
  border: none;
 
  padding: 1rem 3.5rem;
  border-radius: 4px;
  
  font-size: 1rem;

  background: $bg-senai;
  color: $bg-base;

  cursor: pointer;
}

.confirmation-modal-footer{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}