.proposals-page-container {
    width: 100%;
    height: 94%;
    padding: 2vw;
    display: flex;
    gap: 20px;
    flex-direction: column;
    position: relative;

    .proposals-page-header-container{
        width: 100%;
        border-bottom: 3px solid #000;

        .title-page {
            font-size: 1.25rem;
        }
    }


    .chart-container {
        width: 100%;
        height: auto;
        min-height: 25vw;
        // background-color: $bg-base;
        border-radius: 20px;
        // box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
        border: solid 1px #bbb;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .subtitle-container{
            width: 98%;
            display: flex;
            padding-left: 12px;
            padding-bottom: 14px;
            flex-direction: column;

            .subtitle{
                font-size: 14px;
                align-self: flex-start;
            }
        }
    }

    .proposals-card-container{
        width: 100%;
        height: 100%;
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 50%);
    }
}

.main {
    height: 94%;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.relatory-container {
    background-color: #fff;
    width: 100%;
    height: 94%;
    padding-bottom: 30px;
    overflow: scroll;
    overflow-x: hidden;
}

.options-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .option-button {
        border: none;
        height: 50px;
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 3px 5px 17px -7px rgba(0, 0, 0, 0.53);
        box-shadow: 3px 5px 17px -7px rgba(0, 0, 0, 0.53);
        transition: all .4s;
        border-radius: 10px;
        background-color: #9a1915;
        color: white;
        font-size: 16px;
        font-weight: bold;
        user-select: none;
    
        img {
            width: clamp(18px, 10vw, 32px);
            height: clamp(16px, 10vw, 30px);
    
            svg {
                fill: #555;
                color: #fff;
            }
        }
    }
}


.option-button:hover {
    filter: opacity(.5);
}

.option-button:active {
    scale: .9;
}

.logo-container {

    margin-bottom: 10px;

    img {
        width: 11vw;
        min-width: 140px;
        min-height: 30px;
    }
}

.relatory {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2vh;
    user-select: none;
    width: 100%;
}

.action-plans {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0px 20px 0px;

    .stage-title {
        text-align: left;
        margin: 0;
        font-weight: $font-bold;
        width: 60%;
    }

    .stage-item {
        margin-left: 10px;
        font-size: clamp(.8rem, 1vw, 1.5rem);

    }
}

.division-line {
    width: 70%;
    background-color: #000;
    height: 1px;
    margin-bottom: 20px;
}

.date-text {
    font-size: clamp(12px, 0.75vw, 2rem);
    margin-bottom: 20px;
}

.senai-logo {
    width: 8vw;
    min-width: 100px;
    min-height: 15px;
    margin-bottom: 20px;
}

.title {
    font-size: clamp(12px, 1vw, 2rem);
    font-weight: 500;
    margin-bottom: 20px;
}

.enterprise-info {
    font-size: clamp(10px, 0.75vw, 2rem);
    margin-bottom: 15px;
}

.stage-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: clamp(.8rem, 1vw, 1.5rem);
    width: 60%;
}

.process-title {
    color: #9a1915;
    font-size: clamp(12px, 0.8vw, 2rem);
    font-weight: 500;
    margin-bottom: 30px;
}

.elevator-image {
    width: 55%;
}

.axle-container {
    display: flex;
    // align-items: center;
    // justify-content: center;
    flex-direction: column;
    width: 60%;
}

.axle-title {
    font-size: clamp(12px, 0.8vw, 1.8rem);

}

.macro-container {
    width: 100%;
    display: flex;
    // align-items: center;
    // justify-content: center;
    flex-direction: column;
    padding: 2.5vh;
    gap: 2vh;
}

.question-container {
    // gap: 1vh;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    margin-bottom: 20px;
    width: 100%;



}

.question-title {
    font-size: clamp(.9rem, 1vw, 1.75rem);
    // padding-left: 4vw;
    // padding-right: 4vw;
    margin-bottom: 10px;
    font-weight: $font-semiBold;
    margin-left: 20px;
    width: 100%;
}

.question-enuncied {
    font-size: clamp(13px, 0.8vw, 1.4rem);
    // padding-left: 3vw;
    // padding-right: 3vw;
    margin-bottom: 10px;
}

.question-resp {
    color: #9a1915;
    font-size: clamp(13px, 0.8vw, 1.4rem);
    // padding-left: 3vw;
    // padding-right: 3vw;
    // margin-left: 20px;
}

.graph-title {
    font-size: clamp(12px, 0.8vw, 2rem);
    color: #9a1915;
    font-weight: 500;
    margin-bottom: 20px;
}

.confirmation-container {
    width: 60%;
    height: 26vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4vh;
    background-color: #6c6b6b;
}

.confirmation-text {
    font-size: clamp(12px, 0.8vw, 2rem);
    width: 70%;
    color: #fff;
    text-align: center;
    font-weight: 600;
}

.confirmation-button {
    border-style: none;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 20px;
    border-radius: 8px;
}

.confirmation-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.title-and-image-observation {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    align-self: flex-start;

    img {
        height: 35px;
        margin-right: 20px;
    }
}

.question-container-efficiency {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    .question-content-container {
        width: calc(100% - 45px - 20px);
        margin-left: auto;
        display: flex;
        flex-direction: column;

        .question-enuncied-efficiency {
            margin-bottom: 10px;
        }

        .question-resp-efficiency {
            color: $bg-senai;
        }
    }
}

.loading-container {
    background-color: #fff;
    width: 75%;
    height: 90%;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading-text {
   font-size: 2rem;
   color:  #9a1915;     
}