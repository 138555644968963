.confirm-modal-container {
    padding: 16px;

    background-color: $bg-modal;

    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: clamp(16px, 5vh, 24px);

    .message {
        font-size: clamp(12px, 2.5vw, 20px);
        text-align: center;
    }

    .button-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        gap: clamp(16px, 5vh, 48px);

        button {
            min-width: 120px;
            padding: 8px 16px;
            height: 48px;
            border-style: none;
            border-radius: 4px;

            &#positive-button {
                background-color: $input-border;
                cursor: pointer;
            }

            &#negative-button {
                cursor: pointer;
                background-color: #c6302b;
                color: white;
            }
        }
    }
}
