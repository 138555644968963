.efficiency-level {
  flex-direction: row;
  display: flex;
  padding-left: 5rem;
  flex: 0.8;
  .content {
    display: flex;
    width: 100%;

    .traffic-light-container {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      .image-semaphore {
        width: 100%;
        min-width: 15rem;
        height: 100%;
      }
      .grade {
        position: absolute;
        font-size: 1rem;
        text-align: left;
        left: -245px;
        width: 20rem;
      }
    }
  }
}

@media (max-width: 1554px) {
  .efficiency-level {
    flex-direction: column;
    display: flex;
    padding-left: 5rem;
    flex: 0.8;
    .content {
      padding-top: 1rem;
      padding-left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      .traffic-light-container {
        width: 100%;
        height: 50%;
        display: flex;
        position: relative;
        
        .image-semaphore {
          width: 100%;
          height: 100%;
        }
        .grade {
          position: absolute;
          font-size: 1rem;

          text-align: right;
          &.grade-green {
            top: 55%;
            right: 0;

          }
          &.grade-yellow {
            top: 28%;
            right: 70%;
          }
          &.grade-red {
            top: 35%;
            right: 60%;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .efficiency-level {
    flex-direction: column;
    .content {
      padding-top: 1rem;
      padding-left: 0;
      width: 100%;
      display: flex;

      position: relative;


      flex-direction: column;
      .traffic-light-container {
        width: 100%;
        height: 50%;
        display: flex;

        .image-semaphore {
          width: 100%;
          height: 100%;
          
        }
        .grade {
          position: absolute;
          font-size: 1rem;

          text-align: right;
          
          &.grade-green {
            top: 55%;
            width: 100%;
          }
          &.grade-yellow {
            top: 30%;
            left: 82%;
            width: 100%;
          }
          &.grade-red {
            top: 35%;
            left: 82%;
          }
        }
      }
    }
  }
}
