.specialty-title {
  span {
    color: #7e7e7e;
    font-size: clamp(0.8rem, 1.4vw, 1.4vw);
  }

  margin-bottom: 1.5vh;
}
.specialty-main {
  width: 80%;
  height: 70%;

  margin: 0.7vh 2vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.specialty-filter-item {
  margin: 1.2vh 0;
  font-size: clamp(0.4rem, 1vw, 0.6vw);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    width: 80%;

    color: #bcbcbc;
    margin-left: 4px;
  }
}

.default-specialty {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  p {
    color: #7e7e7e;
    font-size: clamp(0.5rem, 1.1vw, 0.7vw);
  }
}

.specialty-filter-item-container {
  margin: 1.2vh 0;
  font-size: clamp(0.4rem, 1vw, 0.6vw);
  width: 100%;
  gap: 3%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label {
    width: 80%;
    color: #bcbcbc;
    margin-left: 4px;
  }
}
.specialty-input-search-box {
  border: 1px solid $border-base;
  border-radius: 8px;
  padding-right: 3%;
}

.specialty-itens-container {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9a1915;
  }

  &::-webkit-scrollbar-track {
    background: #e7e7e7;
  }
}

.specialty-container {
  width: 20vw;
  height: 100%;

  // margin-top: 9vh !important;
  padding: 4%;
  margin: 0.7vw;
  margin-bottom: 0;
  background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  gap: 2%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  // overflow-y: auto;
  overflow-x: hidden;
}

.specialty-input-container {
  width: 100%;
  background-color: #fff;
  display: flex !important;
  flex-direction: column !important;

  input {
    width: 100%;
    height: 4vh;
    text-overflow: ellipsis;
    border: none;
    color: #bcbcbc;
    padding-left: 2%;
    font-size: clamp(0.7rem, 0.8vw, 1rem);

    &:focus {
      outline: none;
      border-color: $bg-senai;
  }

    &::placeholder {
      font-size: clamp(0.7rem, 0.8vw, 1rem);
      color: #bcbcbc;
    }
  }

  img {
    width: 7%;
  }
}
.specialty-edit-button {
  width: clamp(10px, 1vw, 20px);
  height: clamp(10px, 1vw, 20px);
  background-color: transparent;
  border: none;
  cursor: pointer;
  background-size: cover;
  background-image: url("../../../../../public/assets/icons/edit-red.png");
}

.specialty-delete-button {
  width: clamp(10px, 1vw, 20px);
  height: clamp(10px, 1vw, 20px);
  background-color: transparent;
  border: none;
  cursor: pointer;
  background-size: cover;
  background-image: url("../../../../../public/assets/icons/trash-red.png");
}
