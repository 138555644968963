.create-table-container {
  width: auto;
  height: auto;
  overflow: scroll;
  .table-main-container {
    width: "100%";
    height: "100%";
    border-spacing: 0;

    .table-columns-container {
      display: flex;

      .columns-lines {
        width: 7vw;
      }
    }
  }
}

.create-table-column {
  display: flex;
  width: 7vw;
  padding: 0 10px 0 16px;
  height: 100%;
  justify-content: "center";
  align-items: "center";

  input {
    width: 100%;
    height: 80%;
    background-color: $bg-base;
    border: 0;
    border-bottom: 2px solid $font-color-dark;
    border-radius: 3px;
    font-size: 14px;
    padding-left: 2%;

    font-size: clamp(4px, 1.5vw, 12px);
    color: $font-color-dark;
    font-weight: $font-medium;
  }

  &.row-description {
    margin-top: 20px;
  }
}

 .table-columns-action {
  display: flex;
  padding: 0 10px 0 16px;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-weight: regular;
  font-size: 16;
  cursor: pointer;
}

// style={{ height: "100%", width: "auto", maxWidth: "220px", wordBreak: "break-word", display: "flex", alignItems: "center", backgroundColor: index % 2 ? "#f4f4f4" : "#eaeaea", padding: "8px", fontSize: 14 }}
