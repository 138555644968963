.process-type-modal-container{
  width: $modals-width;  
  height: 92%;

  position: fixed;
  z-index: 99;

  top: 8%;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;

  select{
    width: 70px;
  }
}

.process-type-modal-close-container{
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
}

.close-button{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.process-type-modal{
  width: 500px;
  min-width: 400px;
  height: 260px;

  display: flex;    
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color: $bg-base;
}

.process-type-modal-header{
  margin: 0 0 2vh 0;
}

.process-type-modal-header span{
  font-family: $font-base;
  font-weight: 300;
  font-size: 120%;
  
  color: $bg-senai;
}

.process-type-modal-body{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input[type="radio"]{
  margin-right: 5px;
}

.process-type-modal-footer {
   margin-top: 3vh;
}

.process-type-modal-footer button {
  border: none;
 
  padding: 0.8rem 3rem;
  border-radius: 4px;
  
  font-size: 1rem;

  background: $bg-senai;
  color: $bg-base;
}



