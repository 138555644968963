.companies-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 95%;

    .pagination-container {
        margin-right: 10vw;
    }
}

.journey-container {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding: 3vh 1.2vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    overflow-y: visible;
    overflow-x: hidden;
}

.journey-card {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    .specialty-cell-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .actions {
            display: flex;
            flex-direction: row;
            gap: clamp(1rem, 4vw, 2rem);

            img {
                width: clamp(16px, 1vw, 24px);
                height: clamp(16px, 1vw, 24px);
                margin: 0;
            }
        }
    }

    .data-view-search {
        height: clamp(8px, 1.2vw, 42px);
        width: clamp(150px, 15vw, 200px);
        display: flex;
        justify-content: center;
        align-items: center;
        //add
        border-bottom: 2px solid $font-color-dark;

        // border: 1px solid $input-border;

        transition: width 0.25s ease-out, border 0.25s ease-out;

        &:focus-within {
            border-color: $bg-senai;
            width: clamp(250px, 30vw, 400px);
        }

        input {
            height: 100%;
            width: 100%;
            padding: 8px;

            background-color: transparent;

            font-size: clamp(4px, 0.8vw, 12px);
            color: $font-color-dark;
            font-weight: $font-medium;

            outline: none;
            border: none;
        }

        img {
            width: 1.2vw;
            padding: 8px;
            padding-left: 0;
        }
    }
}

.journey-filter {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
