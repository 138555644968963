.enterprise-token-page {
  width: 100vw;
  height: 100vh;
}

.enterprise-token-header {
  background-color: $bg-senai;
  width: 100%;
  height: 15%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.enterprise-token-content {
  background-color: $bg-base;
  width: 100%;
  height: 85%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.enterprise-token-header-image {
  height: 50%;
  width: auto;
  min-width: 5%;

  img {
    width: 100%;
    height: 100%;
  }
}

.enterprise-token-content-container {
  width: 40%;
  height: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  p {
    font-size: clamp(10px, 1.2vw, 20px);
    color: #252525;
    text-align: center;

    &:nth-child(1),
    &:nth-child(3) {
      font-size: clamp(15px, 1.2vw, 30px);
      font-weight: 500;
    }

    &:nth-child(2) {
      color: #1a5cbe;
    }
  }
}

.enterprise-token-content-input-container {
  width: 100%;
  height: 10%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .enterprise-token-content-input {
    width: 3.5vw;
    height: 3.5vw;

    // min-width: 40px;
    // min-height: 40px;

    background-color: #fff;
    border: 1px solid rgb(101, 101, 101);
    border-radius: 4px;
    text-align: center;
    font-size: clamp(10px, 2vw, 36px);
  }
}

.enterprise-token-content-resend-token {
  color: #1a5cbe;
  text-decoration: underline;
  cursor: pointer;
}

.enterprise-token-content-button {
  color: $bg-base;
  background-color: #79b555;
  border-radius: 5px;
  border: 0px;
  width: 30%;
  min-width: 200px;
  height: 8%;
  font-size: clamp(14px, 2vw, 18px);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #57863b;
  }
}

// @media (max-widht: 650px) {

// }

@media (max-width: 750px) {
  .enterprise-token-content-container {
    width: 60%;
    height: 70%;

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: space-evenly;

    p {
      font-size: clamp(12px, 1.2vw, 30px);
      color: #252525;
      text-align: center;

      &:nth-child(1),
      &:nth-child(3) {
        font-size: clamp(16px, 1.2vw, 40px);
        font-weight: 500;
      }

      &:nth-child(2) {
        color: #1a5cbe;
      }
    }
  }

  .enterprise-token-content-input-container {
    .enterprise-token-content-input {
      width: 6vw;
      height: 6vw;
    }
  }
}

@media (max-width: 400px) {
  .enterprise-token-content-input-container {
    .enterprise-token-content-input {
      width: 8vw;
      height: 8vw;
    }
  }
  .enterprise-token-content-button {
    min-width: 100px;
    font-size: clamp(4px, 3vw, 10px);
  }
}

// .enterprise-token-content-text {

// }
