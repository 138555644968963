.diagnostic-modal-container {
  width: $modals-width;
  height: 92%;

  position: fixed;
  z-index: 99;

  right: 0;
  top: 8%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;
}

.diagnostic-modal {
  width: 30%;
  min-width: 400px;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 0.5%;
  gap: 16px;

  background-color: $bg-base;
}

.diagnostic-modal-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding-left: 5%;

  span {
    width: 100%;

    font-family: $font-base;
    font-weight: 400;
    font-size: 1.3rem;
    color: $bg-senai;
  }
}

.diagnostic-modal-body {
  width: 95%;
  height: 100%;
  textarea {
    width: 100%;
    height: 100%;
    padding: 8px;

    font-size: 1rem;

    resize: none;
  }
}

.diagnostic-modal-footer {
  width: 30%;
  height: 10%;

  button {    
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $bg-senai;
    color: $bg-base;
    border: none;
    border-radius: 4px;

    padding: 0.8rem 3rem;

    font-size: 1rem;
    
    transition: .5s;

    &:hover {
      cursor: pointer;
      background-color: #7c1114;
    }
  }
}
