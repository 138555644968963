.data-view-table-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // padding: 20px;

    .data-view-search-wrapper {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: flex-end;
        // width: 100%;
        // margin-right: 5%;
        // margin-top: 8px;
        // margin-bottom: 8px;

        .step-filter {
            select {
                width: fit-content;
            }
        }

        .data-view-search {
            height: clamp(8px, 1.2vw, 42px);
            width: clamp(150px, 15vw, 200px);
            display: flex;
            justify-content: center;
            align-items: center;
            //add
            border-bottom: 2px solid $font-color-dark;

            // border: 1px solid $input-border;

            transition: width 0.25s ease-out, border 0.25s ease-out;

            &:focus-within {
                border-color: $bg-senai;
                width: clamp(250px, 30vw, 400px);
            }

            input {
                height: 100%;
                width: 100%;
                padding: 8px;

                background-color: transparent;

                font-size: clamp(4px, 0.8vw, 12px);
                color: $font-color-dark;
                font-weight: $font-medium;

                outline: none;
                border: none;
            }

            img {
                width: 1.2vw;
                padding: 8px;
                padding-left: 0;
            }
        }
    }

    .data-view-table-container {
        height: 100%;
    }

    table {
        width: 100%;
        height: 100%;
        border-spacing: 0;

        tbody {
            display: block;
            overflow-y: scroll;
            height: 96%;
            min-height: 65vh;

            tr {
                display: table;
                table-layout: fixed;
                width: 100%;
            }
        }

        tr {
            display: table;
            table-layout: fixed;
            width: 100%;

            .dropped,
            img {
                width: 0.7vw;
                height: 0.7vw;
                margin-left: 40%;
                // transform: rotate(180deg);
            }

            .dropped {
                transform: rotate(-90deg);
            }

            cursor: pointer;

            &:hover {
                td {
                    filter: brightness(0.98);
                }
            }

            .status {
                padding: 0.1rem;
            }

            td {
                padding: clamp(0.4rem, 0.8vw, 1rem);
                border-bottom: 1px solid $input-border;
                text-align: left;
                background-color: #fff;
                transition: filter 0.25s ease-out;

                color: $font-color-dark;
                font-size: clamp(8px, 0.6vw, 14px);
                text-transform: uppercase;

                .actions {
                    cursor: pointer;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 7%;
                    }
                }
            }

            th {
                padding: clamp(0.2rem, 0.6vw, 1rem);
                font-size: clamp(0.4rem, 0.8vw, 1.25rem);
                border-top: 1px solid $input-border;
                border-bottom: 1px solid $input-border;

                background-color: $bg-base;

                text-align: left;

                transition: filter 0.25s ease-out;

                &.name {
                    width: 10vw;

                    .table-header-content {
                        padding-left: 1.5vw;
                    }
                }

                &.unity {
                    padding-left: 2vw;
                }

                &.cnpj {
                    .table-header-content {
                        padding-left: 0.5vw;
                    }
                }

                &.city {
                    .table-header-content {
                        padding-left: 0.5vw;
                    }
                }

                &.proposal {
                    width: 10vw;
                    padding-right: 1vw;

                    .table-header-content {
                        width: 70%;
                    }
                }

                &.consultant {
                    width: 10vw;
                }

                &.created-date {
                    padding-right: 1vw;
                    width: 10vw;
                }

                &:hover {
                    filter: brightness(0.98);
                }

                .table-header-content {
                    font-size: clamp(0.4rem, 0.8vw, 1.25rem);
                    display: flex;
                    gap: 0.2vw;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;

                    .order-selector-button {
                        height: 100%;
                        aspect-ratio: 1/1;
                        // font-size: 1.25rem;
                        cursor: pointer;

                        font-size: clamp(0.6rem, 0.8vw, 1.25rem);

                        border: none;
                        background-color: $bg-base;
                    }
                }
            }
        }
    }

    footer {
        width: 100%;
        height: 5%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem;
        background-color: $bg-base;
        border-bottom: 1px solid $input-border;
        font-weight: $font-bold;
        font-size: clamp(0.4rem, 0.8vw, 1.25rem);
    }
}

.company-page-title {
    color: $bg-senai;
    // margin-bottom: 2%;
    font-size: clamp(0.8rem, 1.2vw, 1.5rem);
    // font-size: clamp(0.7rem, 1.2vw, 1.5rem);
    font-weight: $font-medium;
}

.enterprise-table-container {
    height: 90%;
}

.status-tag-container {
    // padding: clamp(0.1rem, 0.8vw, 0.2rem) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10%;
}

.status-tag {
    min-width: 3vw;
    width: auto;
    min-height: 25%;

    background-color: #fff;
    border-radius: 10px;
    border: 1px solid $bg-senai;

    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    gap: 0.1vw;

    padding: 0.2vw;

    margin-bottom: 3px;

    &.selected {
        background-color: $bg-senai;

        p {
            font-weight: $font-bold;
            color: #fff;
        }
    }

    p {
        font-size: clamp(2px, 1.2vw, 6px);
        color: $bg-senai;
        font-weight: $font-bold;
    }
}

.enterprise-table-dropped {
    width: 100%;
    height: auto;
    min-height: 5vh;
    display: flex;
    gap: 2%;
    padding: 1%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .enterprise-table-dropped-item {
        width: auto;
        height: 2vh;
        padding: 0.8%;
        background-color: $bg-base;
        border: 1px solid $font-color-dark;
        border-radius: 10px;
        margin-bottom: 1%;
        color: $font-color-dark;
        font-size: clamp(4px, 0.8vw, 10px);

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.table-header {
    // background-color: aqua;
    width: 100%;
    min-height: 5%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1%;
    // padding-bottom: 1%;
    // margin-bottom: 1%;

    h1 {
        color: $bg-senai;
        font-size: clamp(1.5rem, .8vw, 1.5rem);
        // font-size: clamp(0.8rem, 1.2vw, 1.5rem);
        font-weight: $font-medium;
    }

    button{
        min-width: 12vw;
        width: auto;
        height: 2vw;
        font-size: clamp(0.4rem, 0.8vw, 1.25rem);
        padding: 0 2%;
        background-color: $bg-senai;
        border: none;
    
        color: white;
        border-radius: 8px;
    
        transition: filter 0.25s ease-out;
        cursor: pointer;
    
        &:hover {
            filter: brightness(0.9);
        }
    }

    .header-filters-conatiner{

        display: flex;
        align-items: flex-end;
        gap: 5%;
    }

}