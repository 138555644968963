.avaliation-modal-container{
  width: $modals-width;  
  height: 100%;

  position: fixed;
  z-index: 1;

  right: 0;


  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-screen-modal;
}

.avaliation-modal{
  width: 55%;
  height: 70%;
  max-height: 100%;
  min-height:450px;
  background-color: white;

  display: flex;
  flex-direction: column;
}

.avaliation-modal-close-container{
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
}

.close-button{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.avaliation-modal-header{

  display: flex;
  gap: 2vh;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 5%;
  padding-right: 5%;

  span{
    font-size: 1rem;
  }

  p{
    text-align:left;

    font-size: 0.8rem;
    font-weight: 500;
  }

}

.avaliation-modal-body{
  display: flex;
  margin-left: 5%;
  gap: 10px;
  flex-direction: column;
  justify-content: space-around;

}

.avaliation-container{

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  
}

.text-container{

  width: 30%;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span{
    text-align: left;
    font-size: 0.54rem;
    font-weight: 200;
  }
}

.stars-container{
  width: 65%;

  display: flex;
  justify-content: space-around;
}

.star{
  color: #F1DA05;
  font-size: clamp(12px, 2vw, 24px) !important;
  cursor: pointer;
}

.all-avaliation-container{
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  
  span{

    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .stars-container{
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }  
}

.avaliation-modal-footer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
}

.avaliation-modal-footer button {
  border: none;
 
  padding: 5px;
  border-radius: 4px;
  
  font-size: 1rem;

  cursor: pointer;

  transition: .3s;

  padding: 20px;

  background: $bg-senai;
  color: $bg-base;
}

.avaliation-modal-footer button:hover {


  background: #640606;
}