.reports-registry-container {
    height: 100%;
    width: 100%;
    max-height: 100vh;
    // padding: 4.5vh 1.2vw;

    display: flex;
    justify-content: center;
    align-items: center;
}

.reports-registry-card-container {

    width: 90%;
    height: 85%;

    background-color: #fff;
    padding: 1rem 2rem;
    border-radius: 8px;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;

    p {
        height: 5%;
        width: 100%;
        color: $font-color-dark;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: clamp(8px, 1.5vw, 18px);
    }
}

.reports-registry-card {
    width: 100%;
    height: 95%;

    overflow-y: scroll;

    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 10% 0;

    &::-webkit-scrollbar {
        width: 10px;
        height: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background: #9a1915;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #e7e7e7;
        border-radius: 8px;
    }
}

.report-registry {
    width: 20vw;
    height: 12vh;
    background-color: $bg-base;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid $bg-modal;
    border-radius: 4px;
    cursor: pointer;

    span {
        text-align: center;
        color: $bg-senai
    }

    &:hover {
        background-color: $bg-senai;
        transition: 0.3s;

        span {
            color: $bg-base;
        }
    }
}

.report-registry-form{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
}