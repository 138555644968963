.login-container {
  width: 100vw;
  height: 100vh;
  padding: 4vw 8vh;
}

.login-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}

// .login-card {
//   width: 100%;
//   height: 100%;
//   background: #fff;
//   -webkit-box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.5);
//   box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.5);
//   border-radius: 8px;
// }

.login-box-left {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-box-left-logo {
  width: 60%;

  img {
    width: 100%;
    height: 100%;
  }
}

.login-box-left-logo-text {
  margin-top: 20px;
  text-align: end;
  padding-right: 15px;
}

.login-box-left-logo-text-upper {
  font-size: clamp(0.7rem, 3vw, 3rem);
}

.login-box-left-logo-text-down {
  font-size: clamp(0.7rem, 3vw, 3rem);
}

.login-box-right {
  background-color: $bg-senai;
  height: 100vh;
  width: 40vw;
  min-width: 400px;
}

.login-box-right-container {
  height: 100%;
}

.login-box-right-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px;

  h3 {
    padding-top: 2vh;
    margin-bottom: 2vh;
    font-size: 3rem;
    font-weight: $font-light;
    color: $font-color;
  }

  form {
    margin-top: 30px;
    width: 100%;
  }
}

.login-box-right-form-field {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;

  label {
    color: $font-color;
    font-weight: $font-thin;
    margin-left: 12px;
    font-size: clamp(0.6rem, 2vw, 1.2rem);
  }

  input {
    height: 7vh;
    border-radius: 4px;
    border: 1px solid $border-base;
    width: 100%;
    margin: 12px 0px;
    padding: 12px;

    &:focus {
      outline: none;
    }
  }
}

.login-box-right-form-button {
  button {
    height: 7vh;
    border-radius: 4px;
    border: 1px solid $border-base;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    background-color: $bg-senai;
    color: $font-color;
    font-weight: $font-light;
    font-size: clamp(0.6rem, 2vw, 1.2rem);

    &:hover {
      background-color: #fff;
      color: $bg-senai;
      font-weight: $font-light;
      cursor: pointer;
    }
  }
}

.login-box-right-forgot-pass {
  margin-top: 2vh;
  gap: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
    background-color: rgba(0, 0, 0, 0);

    span {
      cursor: pointer;
      text-decoration: underline;
      color: #f4f6f6;
      font-weight: $font-light;
      text-align: center;

      &:hover {
        color: #d5d5d5;
      }
    }
  }

}

.mobile-download {
  display: none;
}

.warning-system-migration {
  position: absolute;
  bottom: 2rem;
  color: $font-color;
  font-weight: $font-regular;
  font-size: 0.8rem;
  text-align: justify;

  a {
    text-decoration: underline;
  }
}

@media (max-width: 768px) {
  .login-container {
    padding: 0;
  }

  .login-box-left {
    display: none;
  }

  .login-box-right {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    min-width: 0px;
  }

  .login-box-right-form {
    padding: clamp(16px, 4vw, 32px);
  }

  .mobile-download {
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    p {
      width: 80vw;
      color: white;
      text-align: center;
    }

    a {
      padding: 8px 16px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: $bg-senai;

      border: 1px solid $border-base;
      border-radius: 4px;

      span {
        color: white;
      }

      img {
        width: 48px;
        height: 48px;
      }
    }
  }
}